// gsm 7 characters to also allow { and } for dynamic text based on https://twiliodeved.github.io/message-segment-calculator/
export const gsm7Chars = "@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1bÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà{}";


export const makeGSM7Compliant = (text: string) => {

    let compliantText = '';
    for (let i = 0; i < text?.length; i++) {
        // this is meant to replace some common used  non-gsm characters their equivalent gsm compliant characters
        if (gsm7Chars.indexOf(text[i]) !== -1) {
            compliantText += text[i];
        } else if (text[i] === '’' || text[i] === '‘') {
            compliantText += "'"; // Replace curly single quotes with straight single quote
        } else if (text[i] === '”' || text[i] === '“') {
            compliantText += '"'; // Replace curly double quotes with straight double quote
        } else if (text[i] === '–' || text[i] === '—') {
            compliantText += '-'; // Replace en dash and em dash with a hyphen
        } else if (text[i] === '…') {
            compliantText += '...'; // Replace ellipsis with three dots
        } else if (text[i] === ' ') {
            compliantText += ' '; // Replace non-breaking space with a regular space
        } else if (text[i] === '′') {
            compliantText += "'"; // Replace prime with apostrophe
        } else if (text[i] === '″') {
            compliantText += '"'; // Replace double prime with straight double quote
        } else {
            compliantText += ' '; // Default replacement for unsupported characters
        }
    }
    return compliantText;
}

export const isGSM7Compliant = (text: string) => {

    for (let i = 0; i < text.length; i++) {
        if (gsm7Chars.indexOf(text[i]) === -1) {
            return false;
        }
    }
    return true;
}