import React, { useState, useEffect } from 'react';
import {
    ResponsiveContainer,
    FunnelChart,
    Funnel,
    Tooltip as RechartToolTip,
    LabelList
} from 'recharts';
import { Box, Paper, Stack } from '@mui/material';
import dataProvider from '../../dataProvider';
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import tokenManager from '../../tokenManager';
import { Mixpanel } from '../../mixpanel';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#00302B',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

export default function AppEngagementFunnel({ setUserQuery, setShowResultingUsers }) {
    
    const [data, setData] = useState<any | []>([]);
    const [comparisonDate, setComparisonDate] = useState(null)
    const [noData, setNoData] = useState(false)

    useEffect(() => {
        setTimeout(()=>{ // migrated functionality of "getApiData" to here to fix build warning
            if(tokenManager.getToken()) {
                dataProvider.get(`kaizenovate/provider/1.0.0/app_engagements?practice_id=${sessionStorage.getItem('selectedPractice')}`).then(result => {
                    setData(result.data.data);
                    if (result.data.comparison_date) {
                        setComparisonDate(result.data.comparison_date)
                    }
                    if (result.data.empty_data) {
                        setNoData(true)
                    }
                });
            }
        }, 100)
    }, []);

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5' fontWeight='bold' style={{ marginRight: 4 }}>
                    App Engagement
                </Typography>
                <HtmlTooltip
                    title={
                    <React.Fragment>
                        {"Active Patients:"}<li>{"Count of patients with a future appointment"}</li>
                        <br />
                        {"Setup Account:"}<li>{"Count of patients who have created an account and linked to their record"}</li>
                        <br />
                        {"Onboarded:"}<li>{"Count of patients who have completed account setup and onboarding in the app"}</li>
                        <br />
                        {"Engaged:"}<li>{"Count of patients who have engaged with the app after account setup and onboarding"}</li>
                    </React.Fragment>
                    }
                >
                    <InfoOutlined fontSize='medium' style={{ opacity: 0.6 }} />
                </HtmlTooltip>
            </div>
            <div>
                <div style={{ margin: 'auto', height: 364 }}>
                    {noData ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                            <Typography variant='h6' textAlign='center'>
                                No data available
                            </Typography>
                        </div>
                    ) : (
                        <ResponsiveContainer>
                            <FunnelChart width={730} height={250}>
                                <RechartToolTip
                                    formatter={(value, name, props) => {
                                        return [props.payload.payload.label, name]
                                    }}
                                />
                                <Funnel
                                    dataKey='value'
                                    data={data}
                                    isAnimationActive
                                    onClick={(data) => {
                                        console.log(data)
                                        setUserQuery(data.query)
                                        setShowResultingUsers(true)
                                        Mixpanel.track('PROV.Dashboard.AppEngagementFunnel', { section_clicked: data.name })
                                    }}
                                >
                                    <LabelList
                                        dataKey='label'
                                        position='insideTop'
                                        offset={20}
                                        stroke='none'
                                        content={({ x, y, value, index, width, height }) => (
                                            <text
                                                x={Number(x) + Number(width) / 2}
                                                y={(Number(y) + Number(height) / 2) - 18}
                                                fill={index !== undefined && index < 2 ? 'black' : 'white'}
                                                textAnchor='middle'
                                                fontSize='16px'
                                                fontWeight='bold'
                                                style={{ pointerEvents: 'none', textShadow: index !== undefined && index < 2 ? '1px 1px 2px white' : '1px 1px 2px black' }}
                                            >
                                                {value}
                                            </text>
                                        )}
                                    />
                                    <LabelList
                                        dataKey='change'
                                        position='inside'
                                        stroke='none'
                                        content={({ x, y, value, index, width, height }) => (
                                            <g>
                                                <text
                                                    x={(Number(x) + Number(width) / 2) - 18}
                                                    y={(Number(y) + Number(height) / 2) + 2}
                                                    fill={index !== undefined && index < 2 ? 'black' : 'white'}
                                                    textAnchor='middle'
                                                    fontSize='16px'
                                                    stroke={index !== undefined && index < 2 ? 'black' : 'white'}
                                                    strokeWidth='1'
                                                    style={{ pointerEvents: 'none', textShadow: index !== undefined && index < 2 ? '1px 1px 2px white' : '1px 1px 2px black' }}
                                                >
                                                    {value ? String(value).split(" ")[0] : ''}
                                                </text>
                                                <text
                                                    x={(Number(x) + Number(width) / 2) + 10}
                                                    y={(Number(y) + Number(height) / 2) + 3}
                                                    fill={index !== undefined && index < 2 ? 'black' : 'white'}
                                                    textAnchor='middle'
                                                    fontSize='14px'
                                                    style={{ pointerEvents: 'none', textShadow: index !== undefined && index < 2 ? '1px 1px 2px white' : '1px 1px 2px black' }}
                                                >
                                                    {value ? (String(value).split(" ")[1] + "*") : ''}
                                                </text>
                                            </g>
                                        )}
                                    />
                                </Funnel>
                            </FunnelChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </div>
            {!noData && <div style={{height: '48px'}}>
                <Stack direction='row' spacing={2} justifyContent='center' sx={{ mt: 2 }}>
                  {data.map((item) => (
                    <Stack key={item.name} direction='row' alignItems='center' spacing={1}>
                      <Box sx={{ width: 10, height: 10, bgcolor: item.fill, borderRadius: '50%' }} />
                      <Typography fontSize='12px'>{item.name}</Typography>
                    </Stack>
                  ))}
                </Stack>
            </div>}
            {!noData && comparisonDate && <Typography variant='caption' textAlign='center'>
                {`* since ${comparisonDate}`}
            </Typography>}
        </Paper>
    );
}