import { SxProps, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldProps, useRecordContext, useUpdate, useGetIdentity, useRedirect } from 'react-admin';
import { Patient } from '../../types';
import { format } from 'date-fns';
import dataProvider from '../../dataProvider';
import tokenManager from '../../tokenManager';
import { logActivity } from '../../logActivity.js'
import SessionTimeout from '../../SessionTimeout';
import '../../scss/pages/PatientDetails.scss';
import UserConditionList from '../../components/patients/patientdetails/UserConditionsList';
import PatientDetails from '../../components/patients/patientdetails/PatientDetails';
import AppointmentHistory from '../../components/patients/patientdetails/AppointmentHistory';
import AppFeedbackHistory from '../../components/patients/patientdetails/AppFeedbackHistory';

interface Props extends FieldProps<Patient> {
    size?: string;
    sx?: SxProps;
}

const PatientDetailsTab = (props: Props) => {

    //date for automated testing, 0 if not testing
    var date = Number(sessionStorage.getItem('testDate'))
    if (!date) {
        date = 0
    }

    const record = useRecordContext<Patient>();
    const [update, { isLoading }] = useUpdate();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const redirect = useRedirect()

    var startTime = new Date();

    // add new activity log
    const updateWithNewRecord = async (newActivity) => {

        if (newActivity.duration <= 1) return

        var currRecord = record;
        if(identityLoading && !isLoading && tokenManager.getToken()) {
            await dataProvider.getOne(`users`, record).then(result => {
                currRecord = result.data;
            });
        }

        var currDate = currRecord.start_billing_date;
        var currTime = currRecord.start_billing_time;
        var currBillingDuration = currRecord.current_billing_duration;

        if( currDate == null || 
            currDate === "" ||
            currTime == null || 
            currTime === "" ) {
            currDate = format(new Date(), 'yyyy-MM-dd');
            currTime = format(new Date(), 'HH:mm');
        }
        
        if( currBillingDuration == null || 
            currBillingDuration === 0) {
            currBillingDuration = newActivity.duration
        } else {
            currBillingDuration = currRecord.current_billing_duration + newActivity.duration
        }

        // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
        const diff = { 
            current_billing_duration: currBillingDuration,
            start_billing_date: currDate,
            start_billing_time: currTime,
        };

        update(
            'users',
            { id: currRecord.id, data: diff, previousData: currRecord }
        )

        logActivity('PROVIDER.View.Details', newActivity.activity, newActivity.comment, currRecord.id, newActivity.duration, date)
    }

    // Add activity log when moving away from Patient Details Tab
    useEffect(
        () => {
            return () => {
                const duration = Math.round((new Date().getTime() - startTime.getTime())/1000);
                var newActivity = {
                    "date": Math.round(Date.now()/1000),
                    "duration": duration,
                    "activity": "Viewed Patient Details Page",
                    "first": identity?.firstName ?? "",
                    "last": identity?.lastName ?? "",
                    "email": identity?.username ?? "", // TODO is email going to be used as username?
                    "picture": identity?.picture ?? "",
                    "comment": "None",
                    "billable": false // TODO check to see if the current viewing period is billable
                };
                updateWithNewRecord(newActivity);
            }
        },
        [!identityLoading]
    );

    return (
        <div className='patientDetails'>
            <SessionTimeout isAuthenticated={true} logOut={() => {redirect('/users')}}/>
            <Grid container width={{ xs: '100%'}} spacing={2} className="patients">
                <Grid item xs={8}>
                    <PatientDetails record={record} />
                </Grid>

                <Grid item xs={4}>
                    <UserConditionList data={record} />
                </Grid>

                <Grid item xs={12}>
                    <AppointmentHistory record={record} />
                </Grid>

                <Grid item xs={12}>
                    <AppFeedbackHistory record={record} />
                </Grid>
            </Grid>
        </div>
    )
}

export default PatientDetailsTab;
