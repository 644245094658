import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import SessionTimeoutDialog from "./SessionTimeoutDialog";

const timeout = 60_000
const promptBeforeIdle = 30_000

const SessionTimeout = ({isAuthenticated, logOut}) => {
    const [remaining, setRemaining] = useState(timeout)
    const [open, setOpen] = useState(false)

    const onIdle = () => {
        logOut()
    }

    const onActive = () => {
        setOpen(false)
    }

    const onPrompt = () => {
        setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500,
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
    }

    return (
        <SessionTimeoutDialog
            open={open}
            countdown={remaining}
            onContinue={handleStillHere}
            onLogout={logOut}
        />
    )
}
export default SessionTimeout;