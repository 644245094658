import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Datagrid, FunctionField, List, Pagination, useDataProvider, useListContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#00302B',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

const CustomPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const UserList = ({isLoading, setIsLoading, actionType}) => {
    const { data, isFetching, sort, setSort } = useListContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (!sort.field.includes(actionType)) {
            setSort({
                field: actionType === "clinical" ? 'clinical_action_priority' : 'admin_action_priority',
                order: 'DESC'
            })
        }
    }, [actionType, sort, setSort])

    useEffect(() => {
        const selectedPractice = sessionStorage.getItem('selectedPractice')
        if (!isLoading && isFetching && (!data || data.length === 0 || data[0].practice.id !== selectedPractice)) {
            setIsLoading(true)
        } else if (isLoading && !isFetching) {
            setIsLoading(false)
        }
    }, [isLoading, data, isFetching])

    const clinicalActions = {
      6: {
        name: "Increased Symptoms - Visit",
        category: "Message",
        route: "/users/{USER_ID}/patient-messages",
      },
      5: {
        name: "Increased Symptoms - HEP",
        category: "Message",
        route: "/users/{USER_ID}/patient-messages",
      },
      4: {
        name: "HEP Needed or Missing",
        category: "Update Diagnosis",
        route: "/users/{USER_ID}/patient-details",
      },
      3: {
        name: "HEP Pending Approval",
        category: "Review HEP",
        route: "/users/{USER_ID}/home-exercise-plan",
      },
      2: {
        name: "Provide Patient Orientation",
        category: "Approve Action",
        route: "/users/{USER_ID}/patient-details",
      },
      1: {
        name: "Ready for Billing",
        category: "Bill RTM",
        route: `/billings?patient={USER_ID}&status=${encodeURIComponent('Ready for Billing')}`,
      },
    }

    const adminActions = {
      3: {
        name: "New Patient",
        category: "Add to EHR",
        route: "/users/{USER_ID}/new-patient-info",
      },
      2: {
        name: "Cancellation Requested",
        category: "Message",
        route: "/users/{USER_ID}/patient-messages",
      },
      1: {
        name: "Sent Message",
        category: "Message",
        route: "/users/{USER_ID}/patient-messages",
      },
    }

    useEffect(() => {
        const selectedPractice = sessionStorage.getItem('selectedPractice')
        if (!isLoading && isFetching && (!data || data.length === 0 || data[0].practice.id !== selectedPractice)) {
            setIsLoading(true)
        } else if (isLoading && !isFetching) {
            setIsLoading(false)
        }
    }, [isLoading, data, isFetching])

    return (
        <Datagrid
            bulkActionButtons={false}
            sx={{
                '& .RaDatagrid-headerCell': {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    height: '60px',
                },
                '& .MuiTableCell-root': {
                    borderBottom: 'none',
                }
            }}
            isLoading={isLoading}
        >
            <FunctionField
                label="Patient"
                render={(record) => {
                    return (
                        <span
                            style={{
                                cursor: 'pointer',
                                color: '#007AFF',
                                textDecoration: 'underline',
                            }}
                            onClick={(event) => {
                                Mixpanel.track('PROV.Dashboard.KeyOpenTasks.PatientDetails', { userId: record.id })
                                navigate(`/users/${record.id}/patient-details`)
                            }}
                        >
                            {`${record.first} ${record.last}`}
                        </span>
                    )
                }}
            />
            <FunctionField
                label="Description"
                render = {(record) => {
                    if (!record || (actionType === 'clinical' && !record.clinical_action_priority) || (actionType === 'admin' && !record.admin_action_priority)) return null

                    return <span>{actionType === 'clinical' ? clinicalActions[record.clinical_action_priority].name : adminActions[record.admin_action_priority].name}</span>
                }}
            />
            <FunctionField
                label="Action"
                render={(record) => {
                    if (!record || (actionType === 'clinical' && !record.clinical_action_priority) || (actionType === 'admin' && !record.admin_action_priority)) return null

                    return (
                        <Typography component="span">
                            <span
                                style={{
                                    color: '#C62828',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                }}
                                onClick={(event) => {
                                    Mixpanel.track('PROV.Dashboard.KeyOpenTasks.Action', { userId: record.id, action: actionType === 'clinical' ? clinicalActions[record.clinical_action_priority].name : adminActions[record.admin_action_priority].name })
                                    navigate(actionType === 'clinical' ? clinicalActions[record.clinical_action_priority].route.replace('{USER_ID}', record.id) : adminActions[record.admin_action_priority].route.replace('{USER_ID}', record.id))
                                }}
                            >
                                {actionType === 'clinical' ? clinicalActions[record.clinical_action_priority].category : adminActions[record.admin_action_priority].category}
                            </span>
                        </Typography>
                    )
                }}
            />
        </Datagrid>
    )
}

export default function App(props) {
    const dataProvider = useDataProvider()
    const [options, setOptions] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)

    // restore previous filter values
    const storedActionType = sessionStorage.getItem('keyOpenTasksActionType')
    const storedDoctor = sessionStorage.getItem('keyOpenTasksDoctor')

    const [actionType, setActionType] = useState(storedActionType || ((localStorage.getItem('isBillable') === 'true') ? "clinical" : "admin"))
    const [filter, setFilter] = useState<any>({
        practice: sessionStorage.getItem('selectedPractice'),
        ...(actionType === 'clinical' ? { clinical_action_priority: {'>': 0} } : { admin_action_priority: {'>': 0} }),
        ...(storedDoctor ? { statistics_providers: storedDoctor } : {})
    })

    useEffect(() => {
        dataProvider.getList('providers', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: { practice: sessionStorage.getItem('selectedPractice') }
        }).then(({ data }) => {
            setOptions(data.map(provider => ({ id: provider.id, name: provider.name })))
        }).catch(error => console.error(error))
    }, [dataProvider])

    return (
        <Paper
            className="key-open-tasks"
            elevation={3}
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: '100%',
                minHeight: '572px',
                position: 'relative'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>

                {/* Title */}
                <div style={{ display: 'flex', alignItems: 'center', maxWidth: '40%' }}>
                    <Typography variant='h5' fontWeight='bold' style={{ marginRight: 4 }}>
                        Key Open Tasks
                    </Typography>
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            {"Below is your list of prioritized actions. Start from the top and work your way down."}
                            <br /><br />
                            {"Click the red item in the "}<strong>Action</strong>{" column to go directly to the task and complete it"}
                        </React.Fragment>
                        }
                    >
                        <InfoOutlined fontSize='medium' style={{ opacity: 0.6 }} />
                    </HtmlTooltip>
                </div>

                {/* Filters */}
                <div style={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    flexWrap: 'wrap',
                }}>
                    {/* Doctor Filter */}
                    <FormControl size="small" sx={{ width: '200px' }}>
                        <InputLabel>Doctor</InputLabel>
                        <Select
                            value={filter.statistics_providers || ''}
                            onChange={(event) => {
                                let newFilter = {...filter}
                                if (event.target.value) {
                                    newFilter.statistics_providers = event.target.value
                                    sessionStorage.setItem('keyOpenTasksDoctor', event.target.value)
                                } else if (newFilter.statistics_providers) {
                                    delete newFilter.statistics_providers
                                    sessionStorage.removeItem('keyOpenTasksDoctor')
                                }
                                setFilter(newFilter);
                            }}
                        >
                            <MenuItem value="">All</MenuItem>
                            {options.map((option) => (
                                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Type Filter */}
                    <FormControl size="small" sx={{ width: '200px' }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={actionType}
                            onChange={(event) => {
                                let newFilter = {...filter}
                                if (event.target.value === "clinical") {
                                    newFilter.clinical_action_priority = {'>': 0}
                                    delete newFilter.admin_action_priority
                                    sessionStorage.setItem('keyOpenTasksActionType', 'clinical')
                                } else {
                                    newFilter.admin_action_priority = {'>': 0}
                                    delete newFilter.clinical_action_priority
                                    sessionStorage.setItem('keyOpenTasksActionType', 'admin')
                                }
                                setFilter(newFilter);
                                setActionType(event.target.value);
                            }}
                        >
                            <MenuItem value="clinical">Clinical</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div>
                <List
                    resource="users"
                    filter={filter}
                    perPage={10}
                    pagination={<CustomPagination />}
                    exporter={false}
                    component="div"
                    sort={{
                        field: actionType === "clinical" ? 'clinical_action_priority' : 'admin_action_priority',
                        order: 'DESC'
                    }}
                    queryOptions={{ refetchOnWindowFocus: false }}
                    disableSyncWithLocation
                >
                    <UserList isLoading={isLoading} setIsLoading={setIsLoading} actionType={actionType} />
                </List>
            </div>
        </Paper>
    );
};