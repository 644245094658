import { Box, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { 
    Edit,
    SimpleFormIterator,
    SimpleForm,
    TextInput,
    ArrayInput,
    SelectInput,
    ReferenceInput,
    Toolbar,
    Button,
    SaveButton,
    BooleanInput,
    RadioButtonGroupInput,
    required,
    useNotify,
 } from 'react-admin';

 import { useWatch } from 'react-hook-form';

import '../../scss/pages/Layouts.scss';
import TagListField from '../../components/homeexercises/TagListField';

export const MasterprogramsEditModal = ({ record, onCancel, onSuccess, adminMode }) => {
    return <MasterprogramsEditForm record={record} onCancel={onCancel} onSuccess={onSuccess} adminMode={adminMode} />;
}

const MasterprogramsEditForm = ({ record, onCancel, onSuccess, adminMode }) => {

    const MasterprogramsEditToolbar = (  ) => {
    
        return (
            <Toolbar
                sx={{
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    justifyContent: 'space-between',
                    minHeight: { sm: 0 },
                    marginBottom: "2em",
                }}
            >
                <Fragment>
                    <>
                        <Button onClick={onCancel} size='medium' variant="contained" label="Cancel" color="inherit" />
                        {/* <IconButton onClick={onCancel}>
                            <CloseIcon />
                        </IconButton> */}
                        <SaveButton icon={<></>}/>
                        {/* <DeleteButton /> */}
                    </>
                </Fragment>
            </Toolbar>
        );
    };

    const ConditionalReferenceInput = () => {
        const overrideDefault = useWatch({ name: 'override_default' })

        return (
            <div style={{ display: overrideDefault ? 'block' : 'none' }}>
                <ArrayInput source="override_condition_ids" label={"Conditions"} defaultValue={['']}>
                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                        <ReferenceInput
                            source=""
                            reference="conditions"
                            filter={{ custom_hep: false }}
                            sort={{ field: 'name', order: 'ASC' }}
                        >
                            <SelectInput optionValue="id" optionText="name" source="name" label="Condition" isRequired validate={overrideDefault ? required() : undefined} fullWidth/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        )
    }

    const ConditionalExerciseInput = () => {
        const hepType = useWatch({ name: 'hep_type' })

        const defaultExerciseRequired = (value) => {
            if (!value) {
                return 'Please select an exercise or remove this row'
            }
            return undefined
        }

        return (
            <>
                {hepType === 'static' && (
                    <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Daily Exercises
                            </Typography>
                            <ArrayInput source="static_exercises" label={false}>
                                <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                    <ReferenceInput
                                        source="default_exercise_id"
                                        reference="exerciselibrarys"
                                        filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput label="Exercise" optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth/>
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                )}

                {hepType === 'progressive' && (
                    <>
                        <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Level 1 - Relieve Exercises
                                </Typography>
                                <ArrayInput source="relieve_exercises" label={false}>
                                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                        <ReferenceInput
                                            source="easy_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="default_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                            isRequired
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth />
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="hard_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                        </ReferenceInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>

                        <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Level 2 - Restore Exercises
                                </Typography>
                                <ArrayInput source="restore_exercises" label={false}>
                                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                        <ReferenceInput
                                            source="easy_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="default_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                            isRequired
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth />
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="hard_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                        </ReferenceInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>

                        <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Level 3 - Reinforce Exercises
                                </Typography>
                                <ArrayInput source="reinforce_exercises" label={false}>
                                    <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row' fullWidth>
                                        <ReferenceInput
                                            source="easy_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="default_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                            isRequired
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" isRequired validate={defaultExerciseRequired} fullWidth />
                                        </ReferenceInput>
                                        <ReferenceInput
                                            source="hard_exercise_id"
                                            reference="exerciselibrarys"
                                            filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                        </ReferenceInput>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                    </>
                )}
            </>
        )
    }

    return (
        <Edit 
            resource="masterprograms"
            className='masterprograms-edit'
            sx={{ maxWidth: '1020px' }}
            mutationMode='pessimistic'
            id={record.id}
            mutationOptions={{ onSuccess: onSuccess }}
            // transform={transform}
        >
            <Box pt={2} sx={{ width: '100%' }} mt={{ xs: 2, sm: 1 }}>
                <SimpleForm 
                    toolbar={<MasterprogramsEditToolbar />}
                    record={record}
                >
                    <Grid container rowSpacing={1} mb={1} columnSpacing={3}>
                        <Grid item xs={8}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Details
                            </Typography>
                            <TextInput source="name" isRequired validate={required()} fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TagListField
                                source="tags"
                                sourceType="masterprograms"
                                label="Tags"
                                reference="tags"
                                adminMode={adminMode}
                            />
                        </Grid>
                    </Grid>

                    { adminMode ? (
                        <Grid container rowSpacing={1} mb={2} columnSpacing={1}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Condition
                                </Typography>
                                <ReferenceInput
                                    source="condition_id"
                                    reference="conditions"
                                    filter={{ custom_hep: false }}
                                    sort={{ field: 'name', order: 'ASC' }}
                                >
                                    <SelectInput optionValue="id" optionText="name" isRequired validate={required()} source="name" />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container rowSpacing={1} mb={2} columnSpacing={1}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    Condition Mapping
                                </Typography>
                                <BooleanInput
                                    source='override_default'
                                    label='Select a condition and map this protocol to future patients instead of the EMBODI protocol'
                                    helperText={false}
                                />
                                <ConditionalReferenceInput />
                            </Grid>
                        </Grid>
                    )}

                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Protocol Type
                            </Typography>
                            <RadioButtonGroupInput
                                source='hep_type'
                                label=''
                                row={false}
                                choices={[
                                    { id: 'static', name: 'Static - patient performs all exercises daily' },
                                    { id: 'progressive', name: 'Progression - patient performs 3 exercises daily per condition and progresses over time with increased difficulty' },
                                ]}
                                disabled={true}
                                isRequired
                                validate={required()}
                            />
                        </Grid>
                    </Grid>
                    
                    <ConditionalExerciseInput />
                </SimpleForm>
            </Box>
        </Edit>
    );
};
