import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BooleanInput } from 'react-admin';
import { isGSM7Compliant } from './helpers/gsm7ComplianceHelpers';


// This component is used to check if the sms_message has special characters and set the value of has_special_characters accordingly
const CustomSCBooleanInput: React.FC<{ sms_message: any }> = ({ sms_message }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        if (sms_message) {
            if (!isGSM7Compliant(sms_message)) {
                setValue('has_special_characters', true);
   
            } else {
                setValue('has_special_characters', false);
            }
        }
    }, [sms_message, setValue]);

    return (
        <BooleanInput source="has_special_characters" label="Has special characters" sx={{ display: 'none' }} />
    );
};

export default CustomSCBooleanInput;
