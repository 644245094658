import React, { useState, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    Bar,
    Tooltip as RechartToolTip,
    LabelList,
    Cell,
    YAxis,
} from 'recharts';
import dataProvider from '../../dataProvider';
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import tokenManager from '../../tokenManager';
import { Mixpanel } from '../../mixpanel';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#00302B',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

export default function App({ setUserQuery, setShowResultingUsers }) {
    
    const [data, setData] = useState<any | []>([]);
    const [noData, setNoData] = useState(false)

    useEffect(() => {
        setTimeout(()=>{ // migrated functionality of "getApiData" to here to fix build warning
            if(tokenManager.getToken()) {
                dataProvider.get(`kaizenovate/provider/1.0.0/rtm_gap_analysis_98977?practice_id=${sessionStorage.getItem('selectedPractice')}`).then(result => {
                    setData(result.data.data);
                    if (result.data.empty_data) {
                        setNoData(true)
                    }
                });
            }
        }, 100)
    }, []);

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5' fontWeight='bold' style={{ marginRight: 4 }}>
                    RTM Gap Analysis: 98977
                </Typography>
                <HtmlTooltip
                    title={
                    <React.Fragment>
                        {"This graph shows the number of times patients have reported their outcomes."}
                        <br /><br />
                        {"The x-axis represents the number of times a patient submitted a report within the current 30 day consecutive period, while the y-axis shows how many patients reported that specific number of times. Remember the goal is 16 for the 30 day consecutive period. Click any bar to see the list of patients."}
                    </React.Fragment>
                    }
                >
                    <InfoOutlined fontSize='medium' style={{ opacity: 0.6 }} />
                </HtmlTooltip>
            </div>
            <div style={{ height: 364 }}>
                {noData ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Typography variant='h6' textAlign='center'>
                            No data available
                        </Typography>
                    </div>
                ) : (
                    <ResponsiveContainer>
                        <BarChart data={data} barGap={20}>
                            <XAxis dataKey='name' axisLine={false} tickLine={false} tick={{ fill: '#000000' }} />
                            <YAxis domain={[0, (Math.max(...data.map(d => d.value)) || 1) * 1.25]} hide />
                            <RechartToolTip />
                            <Bar
                                dataKey='value'
                                barSize={30}
                                onClick={(data) => {
                                  console.log(data)
                                  setUserQuery(data.query)
                                  setShowResultingUsers(true)
                                  Mixpanel.track('PROV.Dashboard.RTMAnalysisChart98977', { section_clicked: data.name })
                                }}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={index >= data.length - 3 ? '#63AC90' : '#A0D7C2'} />
                                ))}
                                <LabelList dataKey='value' position='top' fill='#333' fontSize={14} fontWeight='bold' />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>
            {!noData && <Box sx={{ textAlign: 'center', height: '48px', width: '100%' }}>
              <Typography variant='body1' fontWeight='bold'>
                {"Qualifying Events (bills at 16)"}
              </Typography>
            </Box>}
        </Paper>
    );
};