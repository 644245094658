import { SxProps, Card, CardHeader, CardContent, Box, Typography } from '@mui/material';
import { FieldProps, useGetOne, useRecordContext } from 'react-admin';
import { Patient } from '../../types';

interface Props extends FieldProps<Patient> {
    size?: string;
    sx?: SxProps;
}

const BillingTab = (props: Props) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const record = useRecordContext()
    const { data, isLoading, error } = useGetOne('patientBillings', {id: record.id})
    if (isLoading) { return <div>Loading</div>}
    if (error) { return <div>Error</div>}

    const formatSeconds = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60)
        const secs = seconds % 60;

        if (hours > 0) {
          return `${hours}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
        }

        return `${minutes}:${String(secs).padStart(2, "0")}`
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '25px',
            }}
        >
            {data['98975']['billing'] && data['98975']['billing'].status !== 'Billed' ?
            <Card variant="outlined">
                <CardHeader titleTypographyProps={{fontWeight:'fontWeightBold'}} title="98975: Onboarding" />
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '50px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                            <Typography mb={1} className="c-grey">
                                Status
                            </Typography>
                            <Box>
                                {data['98975']['billing'] ? data['98975']['billing']['status'] : '-'}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                        <Typography mb={1} className="c-grey">
                                Patient Criteria Met Date
                            </Typography>
                            <Box>
                                {data['98975']['criteriaMetDate'] ? new Date(data['98975']['criteriaMetDate'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                        <Typography mb={1} className="c-grey">
                                Billing Date
                            </Typography>
                            <Box>
                                {data['98975']['billing']['billing_date'] ? new Date(data['98975']['billing']['billing_date'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card> : null}
            {data['98977']['billing'] && data['98977']['billing'].status !== 'Billed' ?
            <Card variant="outlined">
                <CardHeader titleTypographyProps={{fontWeight:'fontWeightBold'}} title="98977: Active 16 out of 30 days" />
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }} >
                        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '50px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Status
                                </Typography>
                                <Box>
                                    {data['98977']['billing'] ? data['98977']['billing']['status'] : '-'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Patient Criteria Met Date
                                </Typography>
                                <Box>
                                    {data['98977']['criteriaMetDate'] ? new Date(data['98977']['criteriaMetDate'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Billing Date
                                </Typography>
                                <Box>
                                    {data['98977']['billing']['billing_date'] ? new Date(data['98977']['billing']['billing_date'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '50px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Start Date
                                </Typography>
                                <Box>
                                    {data['98977']['billing']['start_date'] ? new Date(data['98977']['billing']['start_date'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Active Days in 30 Day Period
                                </Typography>
                                <Box>
                                    {`${data['98977']['activeDays'] ? data['98977']['activeDays'] : '0'}/16`}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card> : null}
            {data['98980']['billing'] && data['98980']['billing'].status !== 'Billed' ?
            <Card variant="outlined">
                <CardHeader titleTypographyProps={{fontWeight:'fontWeightBold'}} title="98980: First 20 minutes + call patient in calendar month" />
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }} >
                        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '50px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Status
                                </Typography>
                                <Box>
                                    {data['98980']['billing'] ? data['98980']['billing']['status'] : '-'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Billing Date
                                </Typography>
                                <Box>
                                    {data['98980']['billing']['billing_date'] ? new Date(data['98980']['billing']['billing_date'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '50px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Current Calendar Month Duration
                                </Typography>
                                <Box>
                                    {`${data['98980']['billing'] ? formatSeconds(data['98980']['duration']) : "-"}/20:00`}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Billing Month
                                </Typography>
                                <Box>
                                    {data['98980']['billing'] ? monthNames[new Date(data['98980']['billing']['start_date'] * 1000).getMonth()] + " " + new Date(data['98980']['billing']['start_date'] * 1000).getFullYear().toString() : '-'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Called Patient
                                </Typography>
                                <Box>
                                {data['98980']['calledPatientDate'] ? new Date(data['98980']['calledPatientDate'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card> : null}
            {data['98981']['billing'][0] && data['98981']['billing'][0].status !== 'Billed' ? 
            data['98981']['billing'].map((item: any, index) => (


            <Card variant="outlined">
                <CardHeader titleTypographyProps={{fontWeight:'fontWeightBold'}} title={"98981: Additional 20 minutes + call patient in calendar month (" + (index + 1) + ")"} />
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }} >
                        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '50px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Status
                                </Typography>
                                <Box>
                                    {item['billing'] ? item['billing']['status'] : '-'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Billing Date
                                </Typography>
                                <Box>
                                    {item['billing']['billing_date'] ? new Date(item['billing']['billing_date'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '50px' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Current Calendar Month Duration
                                </Typography>
                                <Box>
                                    {`${item ? formatSeconds(item['duration']) : "-"}/20:00`}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Billing Month
                                </Typography>
                                <Box>
                                    {item['billing'] ? monthNames[new Date(item['billing']['start_date'] * 1000).getMonth()] + " " + new Date(item['billing']['start_date'] * 1000).getFullYear().toString() : '-'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                <Typography mb={1} className="c-grey">
                                    Called Patient
                                </Typography>
                                <Box>
                                {item['calledPatientDate'] ? new Date(item['calledPatientDate'] * 1000).toLocaleString().toString().split(',')[0] : '-'}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>)) : null}
        </Box>
    )
}

export default BillingTab;