import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';

const Aside = () => {
    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 16em',
                mr: 2,
                alignSelf: 'flex-start',
                mt: 8,
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch source="lastName" label="Last Name" />
            
                <FilterList
                    label="Status"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Activities in Progress"
                        value={{ 
                            status: 'Activities in Progress',
                        }}
                    />
                    <FilterListItem
                        label="98975 Waiting on 98977/80 Completion"
                        value={{ 
                            status: '98975 Waiting on 98977/80 Completion',
                        }}
                    />
                    <FilterListItem
                        label="Ready for Billing"
                        value={{ 
                            status: 'Ready for Billing',
                        }}
                    />
                    <FilterListItem
                        label="Billed"
                        value={{ 
                            status: 'Billed',
                        }}
                    />
                    <FilterListItem
                        label="Did Not Bill"
                        value={{ 
                            status: 'Did Not Bill',
                        }}
                    />
                    <FilterListItem
                        label="Did Not Qualify"
                        value={{ 
                            status: 'Did Not Qualify',
                        }}
                    />
                </FilterList>
                <FilterList
                    label="Code"
                    icon={<AssignmentIcon />}
                >
                    <FilterListItem
                        label="98975 (Onboarding)"
                        value={{ 
                            cpt_code: '98975',
                        }}
                    />
                    <FilterListItem
                        label="98977 (Active 16 days)"
                        value={{ 
                            cpt_code: '98977',
                        }}
                    />
                    <FilterListItem
                        label="98980 (20 Minutes)"
                        value={{ 
                            cpt_code: '98980',
                        }}
                    />
                    <FilterListItem
                        label="98981 (20 Additional Minutes)"
                        value={{ 
                            cpt_code: '98981',
                        }}
                    />
                </FilterList>
    
            </CardContent>
        </Card>
    )
};

export default Aside;
