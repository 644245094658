import { Box, Typography } from '@mui/material';
import {
    CreateButton,
    Datagrid,
    FunctionField,
    List,
    TextField,
    TopToolbar,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ListAside from './ListAside';
import '../../scss/pages/EnhancedMessaging.scss';
import { isGSM7Compliant } from '../../components/smstransparency/helpers/gsm7ComplianceHelpers';
import { InfoOutlined } from '@mui/icons-material';
import { HtmlTooltip } from '../../components/smstransparency/HtmlTooltip';
import { dynamicTextHandling } from '../../components/smstransparency/helpers/dynamicTextHandling';
import { htmlDecode } from '../../components/smstransparency/helpers/htmlDecode';

const MessageTemplateList = () => {

    return (
        <Box mt={2} className="template-container">
            <List
                // sorting by updated date to get most recently modified templates
                sort={{ field: 'updatedAt', order: 'DESC' }}
                aside={<ListAside />}
                actions={<ListActions />}
                filter={{ is_broadcast: false, is_provider_notification: false}}
            >
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                    bulkActionButtons={false}
                >
                    <TextField source="name" label="Template Name" />
                    <FunctionField label="Excerpt" sortBy="sms_message" render={(record: any) => record && record?.sms_message && record?.sms_message.length > 30 ? record?.sms_message.slice(0, 20) + "..." : record?.sms_message} />;
                    {/* <FunctionField label="email_message" sortBy="email_message" render={(record: any) => {
                        console.log("email_message", record?.email_message, typeof record?.email_message, record?.email_message?.length)
                        return record &&  record?.email_message}}/>; */}
                    <FunctionField label="Estimated Credits" render={(record: any) => {
                        let gsm7compliant = false;
                        if (record?.sms_message) {
                            gsm7compliant = isGSM7Compliant(record?.sms_message.replace(/^<p>|<\/p>$/gm, '')) // need to remove html tags 
                        }

                        let parsedMessage = record?.sms_message?.replace(/<p>/gm, '') // removes html tags
                        parsedMessage = parsedMessage?.replace(/<\/p>/gm, '\n') // removes html tags

                        // handle dynamic text
                        let { dynamicTextKeys, estimationLengthMin, estimationLengthMax } = dynamicTextHandling(parsedMessage)


                        let hasTrailingNewLine = parsedMessage?.endsWith('\n'); // if we have an instance of a trailing new line - we shouldn't so we offset the count by 1

                        const messageLength = record?.sms_message?.length > 0 ? ((Array.from(htmlDecode(parsedMessage)!).length - (hasTrailingNewLine ? 1 : 0))) : 0;
                        const newEstimatedCredits = Math.ceil(messageLength / (gsm7compliant ? 160 : 70));

                        // check and handle dynamic text
                        let hasDynamicText = false
                        hasDynamicText = dynamicTextKeys.some(option => record?.sms_message?.includes(option));

                        const dynamicTextRegex = new RegExp(dynamicTextKeys.join('|'), 'g');
                        const displayMin = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - (hasTrailingNewLine ? 1 : 0)) + estimationLengthMin) : 0;
                        const displayMax = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - (hasTrailingNewLine ? 1 : 0)) + estimationLengthMax) : 0;

                        let minDisplay;
                        let maxDisplay;

                        if (hasDynamicText) {
                            let minCalc = Math.ceil(displayMin / (gsm7compliant ? 160 : 70))
                            let maxCalc = Math.ceil(displayMax / (gsm7compliant ? 160 : 70))
                            minDisplay = minCalc
                            maxDisplay = maxCalc
                        }

                        // displays the estimated credits if there are no dynamic text fields, otherwise displays the range 
                        return (record?.sms_message && !hasDynamicText ? newEstimatedCredits : <span style={{ alignItems: "center", display: "flex" }}>{minDisplay === maxDisplay ? maxDisplay : `${minDisplay}-${maxDisplay}`}<HtmlTooltip style={{ marginLeft: "5px" }}
                            title={
                                <Typography color="black">
                                    We are unable to calculate the exact number of credits because you are using Dynamic Text.  Dynamic text can create different lengths for each message.  Therefore, credit usage can vary.
                                </Typography>
                            }
                        >
                            <InfoOutlined fontSize='small' />
                        </HtmlTooltip></span>)
                    }} >
                    </FunctionField>
                    <TextField source="event_type" label="Event Type" />
                    <FunctionField label="Last Updated" sortBy="updatedAt" render={(record: any) => record && record.updatedAt !== 0 ? new Date(record.updatedAt).toLocaleString() : "N/A"} />
                    <FunctionField label="Last Sent" sortBy="last_sent" render={(record: any) => record && record.last_sent !== 0 ? new Date(record.last_sent * 1000).toLocaleString() : "N/A"} />
                    <FunctionField label="In Use" sortBy="in_use" render={(record: any) => record && record.in_use ? <CheckIcon /> : <CloseIcon />} />;
                </Datagrid>
            </List>
        </Box>
    )
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

export default MessageTemplateList