import { Box, TableHead, TableRow, TableCell, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, CircularProgress } from '@mui/material';
import { Children, useEffect, useState } from 'react';
import { Datagrid, List, TextField, FunctionField, ReferenceField, useRecordContext, fetchUtils, useRefresh, useSafeSetState, LoginFormClasses, useNotify, DateField, useListContext } from 'react-admin';
import { useLocation } from 'react-router-dom';
import tokenManager from '../../tokenManager';
import BillingsAside from './BillingsAside';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const BillingsList = (props) => {

    const [loading, setLoading] = useSafeSetState(false);
    const [listLoading, setListLoading] = useState(false)
    const [DialogOpen, setDialogOpen] = useState(false)
    const refresh = useRefresh()
    const notify = useNotify()
    const [billingId, setBillingId] = useState('')

    const formatSeconds = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60)
        const secs = seconds % 60;

        if (hours > 0) {
          return `${hours}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
        }

        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`
    }

    const updateBillingStatus = async (billed) => {
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        await httpClient(`${apiUrl}/kaizenovate/billings/1.0.0/update-status`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                billing: billingId,
                billed: billed,
            }),
        }).catch((e)=> {
            console.log(`Error in logActivity:logActivity()`)
            console.error(e)
            throw e
        })
        setDialogOpen(false)
        refresh()
    }

    const handleClickBilling = async (record, event) => {

        event.stopPropagation()
        
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();
        setLoading(true)

        const copyString = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/get-billing-details`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                billingId: record.id,
            }),
        }).catch((e)=> {
            console.log(`Error in billingsList:handleClickBilling()`)
            console.error(e)
            setLoading(false)
            notify('Failed to fetch billing details', {type: 'error'})
            throw e
        })

        navigator.clipboard.writeText(copyString.body)
        notify('Copied billing details to clipboard', {type: 'info'})
        setLoading(false)
        setBillingId(record.id)
        
        if (record.status !== 'Did Not Qualify') {
          setDialogOpen(true);
        }
    }


    const CopyActivitiesField = ({ source, label }) => {
        const record = useRecordContext();
        if (record && (record[source] !== 'Ready for Billing' )) return (<></>)
        return (<Button sx={{ py: 1.5, backgroundColor: "#2439F2" }} variant="contained" onClick={async (event) => await handleClickBilling(record, event)}>
                    {<ContentCopyIcon />}&nbsp;Copy Activity Details
                </Button>);
    };

    const DataGridHeader = ({ children }) => (
        <TableHead>
            <TableRow>
                {Children.map(children, child => (
                    <TableCell key={child.props.source} sx={{ fontSize: 20 }}>
                        {child.props.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )

    const StatusField = (props) => {
        const record = useRecordContext();
        if (record['status'] === 'Ready for Billing') {
            return <TextField label={props.label} source="status" sx={{color: 'green'}} />
        } else {
            return <TextField label={props.label} source="status" />;
        }
    }

    const QuantityField = ({ label }) => {
        const record = useRecordContext()
        if (record.cpt_code === '98977') {
            return (
                <FunctionField
                    label={label}
                    render={() => {
                        return (
                            <TextField
                                label={label}
                                record={{quantity: `${Math.min(record.log_entries.length, 16)}/16`}}
                                source="quantity"
                            />
                        )
                    }}
                />
            )
        } else if (record.cpt_code === '98980' || record.cpt_code === '98981') {
            return (
                <FunctionField
                    label={label}
                    render={() => {
                        return (
                            <TextField
                                label={label}
                                record={{quantity: `${formatSeconds(record.log_entries.reduce((sum, obj) => sum + obj.duration, 0))}/20:00`}}
                                source="quantity"
                            />
                        )
                    }}
                />
            )
        }
    }

    const BillingList = ({ listLoading, setListLoading }) => {
        const location = useLocation()
        const { data, isFetching, filterValues, setFilters } = useListContext()

        useEffect(() => {
            const params = new URLSearchParams(location.search)
            const patientId = params.get("patient")
            const status = params.get("status")
            
            if (params.size === 0) {
                setFilters({}, {})
            } else if (patientId && status) {
                setFilters({ ...filterValues, patient: patientId, status: status }, {})
            }
        }, [location.search])

        useEffect(() => {
            if (!listLoading && isFetching) {
                setListLoading(true)
            } else if (listLoading && !isFetching) {
                setListLoading(false)
            }
        }, [listLoading, isFetching])

        return (
            <Datagrid
                rowClick="edit"
                bulkActionButtons={false}
                header={<DataGridHeader children/>}
                isLoading={isFetching}
            >
                <FunctionField
                    label="Billing Date"
                    render={record => {
                        var recordCopy = {...record}
                        if (recordCopy.billing_date && recordCopy.billing_date > 0) {
                          recordCopy.billing_date = recordCopy.billing_date * 1000
                        } else {
                          recordCopy.billing_date = null
                        }
                        return <DateField
                            source="billing_date"
                            record={recordCopy}
                            label="Billing Date"
                            emptyText="-"
                            options={{ timeZone: recordCopy["practice"]["time_zone"] }}
                        />
                        
                    }}
                />
                <TextField label="Code" source="cpt_code" />
                <ReferenceField label="Patient Name" source="patient.id" reference="users" />
                <StatusField label="Status"/>
                <QuantityField label="Quantity"/>
                <CopyActivitiesField label="Details" source="status" />
            </Datagrid>
        )
    }

    return (
    <div>
        <div>
            <Dialog
                open={loading!}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                    Loading
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                    <DialogContentText id="alert-dialog-description">
                        Fetching billing details
                    </DialogContentText>
                    <CircularProgress
                        className={LoginFormClasses.icon}
                        size={19}
                        thickness={3}
                        sx={{display: 'flex', alignSelf: 'center', padding: '15px'}}
                    />
                </DialogContent>
            </Dialog>
        </div>
        <div>
            <Dialog
                open={DialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                    Update status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Has this item been submitted for billing in the EHR?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <Button sx={{ color: "#505050" }} onClick={() => setDialogOpen(false)} autoFocus>
                            <CloseIcon />&nbsp;{"Cancel"}
                        </Button>
                    </div>
                    <div>
                        <Button sx={{ color: "#2439F2" }} onClick={() => updateBillingStatus(false)} autoFocus>
                            <CancelIcon />&nbsp;{"Did Not Bill"}
                        </Button>
                        <Button sx={{ color: "#2439F2" }} onClick={() => updateBillingStatus(true)} autoFocus>
                            <CheckCircleIcon />&nbsp;{"Billed"}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
        <Box className="billings-list-container">
            <List
                aside={<BillingsAside />}
                sort={{ field: 'start_date', order: 'DESC' }}
            >
                <BillingList listLoading={listLoading} setListLoading={setListLoading} />
            </List>
        </Box>
    </div>
)};