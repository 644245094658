import { useEffect } from "react";
import { Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { isGSM7Compliant } from "./helpers/gsm7ComplianceHelpers";
import { HtmlTooltip } from "./HtmlTooltip";
import { CustomCleanText } from "./CustomCleanText";
import { ConfirmSpecialText } from "./ConfirmSpecialText";
import { SMSStatusDisplayField } from "./SMSStatusDisplayField";
import { dynamicTextHandling } from "./helpers/dynamicTextHandling";
import { htmlDecode } from "./helpers/htmlDecode";

export const TemplateEstimatesField = ({ templateText, setEstimatedCredits, setSpecialTextNotify, setEstimatedCreditsRange, setIsMultiCredit, estimatedCredits, estimatedCreditsRange, specialTextNotify, notGSMCompliantOnLoad, setNotGSMCompliantOnLoad, clearable, handleCleanPress }) => {

    useEffect(() => {
        // this is to handle the special text notification on load this should 
        // only effect the edit modal as this component wont have this prop
        if (notGSMCompliantOnLoad) {
            setSpecialTextNotify(true);
        }
    }, []);

    const smsMessage = templateText;
    let gsm7compliant = true;
    if (smsMessage) {
        gsm7compliant = isGSM7Compliant(smsMessage.replace(/^<p>|<\/p>$/gm, '')) // only remove p tags at the beginning and end
    }

    let parsedMessage = smsMessage?.replace(/<p>/gm, '') // removes html tags
    parsedMessage = parsedMessage?.replace(/<\/p>/gm, '\n') // removes html tags

    // handle dynamic text
    let { dynamicTextKeys, estimationLengthMin, estimationLengthMax } = dynamicTextHandling(parsedMessage)

    let hasTrailingNewLine = parsedMessage?.endsWith('\n'); // if we have an instance of a trailing new line - we shouldn't so we offset the count by 1

    const messageLength = smsMessage?.length > 0 ? ((Array.from(htmlDecode(parsedMessage)!).length - (hasTrailingNewLine ? 1 : 0))) : 0;
    const newEstimatedCredits = Math.ceil(messageLength / (gsm7compliant ? 160 : 70));
    if (newEstimatedCredits !== estimatedCredits) {
        setEstimatedCredits(newEstimatedCredits);
    }
    // check and handle dynamic text
    let hasDynamicText = false
    hasDynamicText = dynamicTextKeys.some(option => smsMessage?.includes(option));
    if (!gsm7compliant && !specialTextNotify) {
        setSpecialTextNotify(true);
    } else if (gsm7compliant && specialTextNotify) {
        setSpecialTextNotify(false);
        // reset the flag so we can notify user again if they add special text after making basic
        if (setNotGSMCompliantOnLoad) { // this state hook is only available in the edit modal
            setNotGSMCompliantOnLoad(false);
        }
    }

    const dynamicTextRegex = new RegExp(dynamicTextKeys.join('|'), 'g');
    const displayMin = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - (hasTrailingNewLine ? 1 : 0)) + estimationLengthMin) : 0;
    const displayMax = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - (hasTrailingNewLine ? 1 : 0)) + estimationLengthMax) : 0;
    // const displayAvg = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthAvg) : 0;

    let minCalc;
    let maxCalc;
    if (hasDynamicText) {
        minCalc = Math.ceil(displayMin / (gsm7compliant ? 160 : 70))
        maxCalc = Math.ceil(displayMax / (gsm7compliant ? 160 : 70))
        // let avgCalc = Math.ceil(displayAvg / (gsm7compliant ? 160 : 70))
        if (minCalc !== maxCalc) {
            setEstimatedCreditsRange(`${minCalc}-${maxCalc}`);
            setIsMultiCredit(true);
        } else {
            setEstimatedCreditsRange(`${maxCalc}`);
            setIsMultiCredit(true);
        }
    }

    if (!hasDynamicText) {
        setEstimatedCreditsRange("");
    }

    // twilio minimum is 1 credit per message if its more than that on calculation we need to update
    const multiCredit = newEstimatedCredits > 1;
    if (multiCredit) {
        setEstimatedCredits(newEstimatedCredits);
        setIsMultiCredit(true);
    } else {
        // if not already multicredit from above
        if (minCalc > 1 || maxCalc > 1) {
            setIsMultiCredit(true);
        } else {
            setIsMultiCredit(false);
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <SMSStatusDisplayField
                    parsedMessage={parsedMessage}
                    hasDynamicText={hasDynamicText}
                    displayMin={displayMin}
                    displayMax={displayMax}
                    htmlDecode={htmlDecode}
                    hasTrailingNewLine={hasTrailingNewLine}
                    gsm7compliant={gsm7compliant}
                    estimatedCreditsRange={estimatedCreditsRange}
                    estimatedCredits={estimatedCredits} />
                {hasDynamicText && <HtmlTooltip style={{ marginLeft: "5px" }}
                    title={
                        <Typography color="black">
                            We are unable to calculate the exact number of credits because you are using Dynamic Text.  Dynamic text can create different lengths for each message.  Therefore, credit usage can vary.
                        </Typography>
                    }
                >
                    <InfoOutlined fontSize='small' />
                </HtmlTooltip>}
            </div>
            {/* clearable should only be available on template create/edit, and broadcast create (not pre-existing template) */}
            {messageLength > 0 && clearable ? <div style={{ display: 'inline-block' }}><CustomCleanText formField={"sms_message"} setNotGSMCompliantOnLoad={setNotGSMCompliantOnLoad} handleCleanPress={handleCleanPress} /></div> : null}
            {/* this should avoid showing the confirm special text modal on load for applicable edit templates but show appropriately in other templates */}
            {/* We also don't want to show the special text notifications on the forms that are not clearable as the user flow for those should not allow this modal */}
            {specialTextNotify && !notGSMCompliantOnLoad && clearable ? <ConfirmSpecialText formField={"sms_message"} /> : null}
        </div>
    )
}