export const SMSStatusDisplayField = ({ parsedMessage, hasDynamicText, displayMin, displayMax, htmlDecode, hasTrailingNewLine, gsm7compliant, estimatedCreditsRange, estimatedCredits }) => {


    let warningStyle = { color: ((estimatedCredits > 1 || estimatedCreditsRange.length > 1 || (parseInt(estimatedCreditsRange) > 1))) ? "red" : "inherit" }

    let charCount = Array.from(htmlDecode(parsedMessage)!).length - (hasTrailingNewLine ? 1 : 0);

    return <span style={{ fontSize: "14px", ...warningStyle }}>{`${parsedMessage?.length
        ? (hasDynamicText ?
            `${displayMin}-${displayMax}`
            : charCount < 0 ? 0 : charCount)
        : 0} Characters ${hasDynamicText ? "Estimated" : ""} ${gsm7compliant ? "(Basic Text Mode)" : "(Special Character Mode)"} | ${hasDynamicText ? estimatedCreditsRange : estimatedCredits} ${estimatedCredits === 1 && ((estimatedCreditsRange.length === 1 && parseInt(estimatedCreditsRange) <= 1) || estimatedCreditsRange === "") ? "credit" : "credits"} per message ${hasDynamicText ? "estimated " : ""}`}</span>
}

