import { fetchUtils } from "react-admin";
import tokenManager from "../../../tokenManager";

export const fetchPatientEstimates = async (practiceId, selectionCriteriaJSON, advancedQuerySelected) => {
        const rules = selectionCriteriaJSON.rules?.filter((rule: any) => rule.field !== 'default');

        if(rules?.length === 0 ){
            // setEstimatedPatients(0)
            return 0;
        }
              
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const accessToken = tokenManager.getToken();

        if (!practiceId) {
            console.error("Practice ID is missing");
            return;
        }

        const options = {}
        options['credentials'] = 'include'
        options['user'] = {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        }

        const selectionCriteriaJSONEncode = encodeURIComponent(JSON.stringify(selectionCriteriaJSON));
        const url = `${apiUrl}/kaizenovate/communication/1.0.0/get-patient-estimate/${practiceId}/${selectionCriteriaJSONEncode}`;

        const result = await fetchUtils.fetchJson(url, options)

        if (result.status < 200 || result.status >= 300) {
            console.error("An error occurred while trying to sync patient DX codes")
        }

        if (result && result.json.hasOwnProperty('numberOfPatients')) {
            return result.json.numberOfPatients;
        }
    }