import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const  HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgb(250, 250, 251)',
        color: '##000000',
        border: '1px solid rgb(204, 204, 204)',
        fontSize: theme.typography.pxToRem(14),
        maxWidth: 360,
    },
}));