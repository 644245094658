import { Grid } from '@mui/material';
import {
    AutocompleteInput,
    BooleanInput,
    Button,
    CheckboxGroupInput,
    Edit,
    FormDataConsumer,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    TextInput,
    useRecordContext,
    SaveButton,
    Toolbar,
} from 'react-admin';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import '../../scss/pages/EnhancedMessaging.scss';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import SaveIcon from '@mui/icons-material/Save';
import SelectionCriteria from './SelectionCriteria';
import RenderMessageTemplate from './RenderMessageTemplate';
import transformAutomationsData from './TransformAutomationsData';
import ScheduleCriteria from './ScheduleCriteria';
import { ConfirmAutomationMultiCredit } from '../../components/smstransparency/ConfirmAutomationMultiCredit';

const transform = (data: any) => {
    data = {
        ...data,
        practice_id: sessionStorage.getItem('selectedPractice') || '',
        template_id: data.template.id,
        trigger_id: data.trigger?.id,
        is_broadcast: false,
    };

    data = transformAutomationsData(data);

    // delete fields that are not in the model
    delete data.template; // auto populating, only need template_id
    delete data.practice; // auto populating, only need practice_id
    delete data.trigger; // auto populating, only need trigger_id

    return data;
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

/* Start template selection functions */
const FilteredTemplates = ({ event_types }) => {
    if (!event_types) event_types = ['General'];
    const valid_event_types = useWatch().valid_event_types;
    // if valid_event_types is not set or is set and not matching the current event_types, set it to current event_types
    const setValid = (!valid_event_types || (valid_event_types && valid_event_types.join() !== event_types.join()));

    return (<>
        {/* workaround for conditional useFormContext hook call */}
        {setValid && <SetValidEventType event_types={event_types} />}
        <ReferenceInput
            source='template.id'
            reference='messagetemplates'
            filter={{ is_broadcast: false, is_provider_notification: false, event_type: event_types }}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <AutocompleteInput
                optionText='name'
                label='Select a template...'
                filterToQuery={(value) => ({ name: value })}
                validate={[required()]}
            />
        </ReferenceInput>
    </>);
}

const GetTriggerAndFilter = ({triggerId}) => {
    const record = useGetOne('triggers', { id: triggerId }).data;
    let event_filter = ['General'];
    if (record && record.category) event_filter.push(record.category);
    return <FilteredTemplates event_types={event_filter} />;
}

const SetValidEventType = ({ event_types }) => {
    useFormContext().setValue('valid_event_types', event_types);
    return null;
}
/* End template selection functions */

const CustomChannelInput = ({setIncludesSMSChannel}) => {
    const { setValue } = useFormContext();
    const { channels } = useWatch();
    const setChannels = (value) => setValue('channels', value);

    useEffect(() => {
        if (channels && channels.includes('sms')) {
            setIncludesSMSChannel(true);
        } else {
            setIncludesSMSChannel(false);
       
        }
    }, [channels])

    return <CheckboxGroupInput
        source='channels'
        label=''
        choices={[
            { id: 'sms', name: 'Text Message' },
            { id: 'email', name: 'Electronic Mail' },
        ]}
        validate={[required()]}
        defaultValue={['sms']}
        onChange={setChannels}
    />
}

const CustomToolbar = ({ onCancel, isMultiCredit, multiCreditNotify, setMultiCreditNotify, includesSMSChannel, submitBtn }) => {
    const { isDirty } = useFormState();

    return <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Automations just needs a multi credit notification here */}
        <Button
            label='Cancel'
            size='medium'
            variant='contained'
            onClick={onCancel}
        />
        <Button
            label="Save"
            color='primary'
            variant='contained'
            size='medium'
            startIcon={<SaveIcon />}
            disabled={!isDirty}
            // sx={{ display: includesSMSChannel? "" :'none'  }}
            onClick={(event) => {
                event.preventDefault()
                // if the message could be multi credit but channel does not include sms just submit
                if (!includesSMSChannel) {
                    submitBtn.click()
                }
                else if (isMultiCredit) {
                    // if they have already been notified about multi credit usage or they don't have SMS channel selected go ahead and submit
                    if (multiCreditNotify) {
                        submitBtn.click()
                    }
                    setMultiCreditNotify(true);
                } else {
                    submitBtn.click()
                }
            }}
        />
        {/* conditional render this button for includesSMSChannel as something is preventing alternative click*/}
        {/* we want this always enabled as we are handling 'dirtiness' with teh pseudo button */}
        <SaveButton className='automation-save-submit'
            sx={{ display: 'none' }}
            alwaysEnable
        />
    </Toolbar>
}

const MessageAutomationEdit = () => {
    const [isBroadcast, setIsBroadcast]= useState(false)
    const [specialTextNotify, setSpecialTextNotify] = useState(false);
    const [estimatedCredits, setEstimatedCredits] = useState(1);
    const [estimatedCreditsRange, setEstimatedCreditsRange] = useState("");
    const [isMultiCredit, setIsMultiCredit] = useState(false); // TODO may be needed 
    const [multiCreditNotify, setMultiCreditNotify] = useState(false); // 
    const redirect = useRedirect();
    const [currentRecord, setCurrentRecord] = useState<string>("")
    const [includesSMSChannel, setIncludesSMSChannel] = useState(false);

    // save button for the form itself
    const submitBtn = document.querySelector('.automation-save-submit') as HTMLElement

    useEffect(()=> {
        if (isBroadcast && currentRecord){
            redirect('/messagebroadcasts/' + currentRecord);
        }
    },[isBroadcast, currentRecord, redirect])

    const notify = useNotify();

    const RedirectBroadcasts = () => {
        const record = useRecordContext();
        // function sets state based on current record context for eventual redirects
        
        if (record && record.is_broadcast) {
            // setting both state items to trigger use effect for redirect
            setCurrentRecord(record.id.toString())
            setIsBroadcast(true)
        }
        return null;
    };

    const onSuccess = (data: any) => {
        notify('Automation updated', { type: 'info' });
        redirect('/messageautomations');
    }

    const onCancel = (data: any) => {
        redirect('/messageautomations');
    }


    return (
        <Edit
            className='automation-edit'
            sx={{ maxWidth: '1020px' }}
            transform={transform}
            mutationOptions={{ onSuccess }}
            mutationMode='pessimistic'
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='automation'
                toolbar={<CustomToolbar 
                            onCancel={onCancel} 
                            isMultiCredit={isMultiCredit} 
                            multiCreditNotify={multiCreditNotify} 
                            setMultiCreditNotify={setMultiCreditNotify} 
                            includesSMSChannel={includesSMSChannel} 
                            submitBtn={submitBtn}/>}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Edit Automation</h1>
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: '28px' }}>
                        <BooleanInput source='enabled' label='Enabled' />
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <p className='automation-label'>1. Name your automation:</p>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='automation-label'>2. Select your channels:</p>
                    <Grid item xs={12}>
                    <CustomChannelInput setIncludesSMSChannel={setIncludesSMSChannel} />
                    </Grid>
                </Grid>

                <ScheduleCriteria mode='edit' />

                <SelectionCriteria mode='edit' />

                <Grid {...gridProps} className='automation-richtext'>
                    <p className='automation-label'>5. Select your message:</p>
                    <Grid item xs={12}>
                        <FormDataConsumer>
                            {({ formData }) => {
                                if (formData.type === 'event' && formData.trigger && formData.trigger.id) {
                                    return <GetTriggerAndFilter triggerId={formData.trigger.id} />
                                } else if (formData.type === 'appointment') {
                                    return <FilteredTemplates event_types={['General', 'Appointments']} />
                                } else {
                                    return <FilteredTemplates event_types={null} />
                                }
                            }}
                        </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => <>
                            {formData.template && formData.template.id && (
                                <Grid item xs={12}>
                                    <RenderMessageTemplate template_id={formData.template.id} setEstimatedCredits={setEstimatedCredits} setSpecialTextNotify={setSpecialTextNotify} setEstimatedCreditsRange={setEstimatedCreditsRange} setIsMultiCredit={setIsMultiCredit} estimatedCredits={estimatedCredits} estimatedCreditsRange={estimatedCreditsRange} specialTextNotify={undefined} notGSMCompliantOnLoad={undefined} setNotGSMCompliantOnLoad={undefined} includesSMSChannel={includesSMSChannel}/>
                                </Grid>
                            )}
                        </>}
                    </FormDataConsumer>
                    {/* for the AUTOMATION multi credit notification */}
                    {isMultiCredit && multiCreditNotify && includesSMSChannel ? <ConfirmAutomationMultiCredit estimatedCredits={estimatedCredits} estimatedCreditsRange={estimatedCreditsRange} setMultiCreditNotify={setMultiCreditNotify} /> : null}
                </Grid>
                <SendTestMessage origin='automation' />
                <RedirectBroadcasts/>
            </SimpleForm>
        </Edit>
    );
};

export default MessageAutomationEdit;
