import * as React from 'react';
import { Card, CardContent, Collapse } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { FilterList, FilterListItem, FilterLiveSearch, useGetList, useListContext } from 'react-admin';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
    endOfToday,
    addWeeks,
    addMonths,
    endOfMonth,
    endOfWeek,
} from 'date-fns';

import segments from '../../segments/data';
import { subDays } from 'date-fns';
import { useEffect } from 'react';

const Aside = () => {
    const { data: providers, isLoading } = useGetList('providers', {
        filter: { practice: sessionStorage.getItem('selectedPractice'), status: 'active' },
        sort: { field: 'name', order: 'ASC' },
    })

    const { filterValues, setFilters } = useListContext();

    const isTodaySelected = (
        (
            (filterValues.statistics_last_seen?.[">="] === Math.round(new Date(endOfYesterday()).getTime() / 1000)) &&
            (filterValues.statistics_last_seen?.["<="] === Math.round(new Date(endOfToday()).getTime() / 1000))
        ) || 
        (
            (filterValues.statistics_next_visit?.[">="] === Math.round(new Date(endOfYesterday()).getTime() / 1000)) &&
            (filterValues.statistics_next_visit?.["<="] === Math.round(new Date(endOfToday()).getTime() / 1000))
        )
    )

    useEffect(() => {
        if (!isTodaySelected && filterValues.statistics_next_provider) {
            setFilters({
                ...filterValues,
                statistics_next_provider: undefined
            }, {})
        }
    }, [isTodaySelected, filterValues, setFilters])

    const handleNextAppointmentToday = (value, currentFilters) => {
        const newFilters = {...currentFilters}
        if (newFilters.statistics_next_visit) {
            delete newFilters.statistics_next_visit
            if (!newFilters.statistics_last_seen) {
                delete newFilters.statistics_next_provider
            }
        } else {
            newFilters.statistics_next_visit = value.statistics_next_visit
        }
        
        return newFilters
    }

    const handleLastSeenToday = (value, currentFilters) => {
        const newFilters = {...currentFilters}
        if (newFilters.statistics_last_seen) {
            delete newFilters.statistics_last_seen
            if (!newFilters.statistics_next_visit) {
                delete newFilters.statistics_next_provider
            }
        } else {
            newFilters.statistics_last_seen = value.statistics_last_seen
        }
        
        return newFilters
    }

    return (
    
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 16em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch source="first" label="First Name" />

                <FilterLiveSearch source="last" label="Last Name" />
    
                <FilterList
                    label="Patient Action"
                    icon={<LocalOfferIcon />}
                >
                    {segments.map(segment => (
                        <FilterListItem
                            label={segment.name}
                            key={segment.id}
                            value={{ patient_actions: {"contains": segment.name } }}
                        />
                    ))}
                </FilterList>

                <FilterList
                    label="Doctor"
                    icon={<PeopleOutlineIcon />}
                >
                    {isLoading ? (
                        <FilterListItem
                            label="Loading..."
                            value={{}}
                        />
                    ) : (
                        providers!.map(provider => (
                            <FilterListItem
                                label={provider.name}
                                key={provider.id}
                                value={{ statistics_providers: provider.id }}
                            />
                        ))
                    )}
                </FilterList>
                
                <Collapse in={isTodaySelected} timeout={500}>
                    <FilterList
                        label="Today's Doctor"
                        icon={<PeopleOutlineIcon />}
                    >
                        {isLoading ? (
                            <FilterListItem
                                label="Loading..."
                                value={{}}
                            />
                        ) : (
                            providers!.map(provider => (
                                <FilterListItem
                                    label={provider.name}
                                    key={provider.id}
                                    value={
                                        {
                                            statistics_next_provider: provider.name,
                                        }
                                    }
                                />
                            ))
                        )}
                    </FilterList>
                </Collapse>
                
    
                <FilterList
                    label="Last Seen"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Today"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(endOfYesterday()).getTime()/1000),
                                "<=": Math.round(new Date(endOfToday()).getTime()/1000),
                            },
                        }}
                        toggleFilter={handleLastSeenToday}
                    />
                    <FilterListItem
                        label="This week"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(subDays(startOfWeek(new Date()),1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfWeek(new Date())).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Last week"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(subWeeks(subDays(startOfWeek(new Date()),1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(subDays(startOfWeek(new Date()),1)).getTime()/1000),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This month"
                        value={{
                            statistics_last_seen: { 
                                ">=": Math.round(new Date(subDays(startOfMonth(new Date()), 1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfMonth(new Date())).getTime()/1000), 
                             },
                        }}
                    />
                    <FilterListItem
                        label="Last month"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()), 1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(subDays(startOfMonth(new Date()), 1)).getTime()/1000),
                            },
                        }}
                    />
                    <FilterListItem
                        label="Earlier"
                        value={{
                            statistics_last_seen: {"<=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()), 1),1)).getTime()/1000) },
                        }}
                    />
                </FilterList>
    
                <FilterList
                    label="Next Appointment"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Today"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(endOfYesterday()).getTime()/1000), 
                                "<=": Math.round(new Date(endOfToday()).getTime()/1000),
                            },
                        }}
                        toggleFilter={handleNextAppointmentToday}
                    />
                    <FilterListItem
                        label="This week"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(subDays(startOfWeek(new Date()),1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfWeek(new Date())).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Next week"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(addWeeks(subDays(startOfWeek(new Date()),1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(addWeeks(subDays(startOfWeek(new Date()),1),2)).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="This month"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(subDays(startOfMonth(new Date()),1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfMonth(new Date())).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Next month"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(addMonths(subDays(startOfMonth(new Date()),1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(addMonths(subDays(startOfMonth(new Date()),1),2)).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Earlier"
                        value={{
                            statistics_next_visit: {
                                "<=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()),1),1)).getTime()/1000), 
                            },
                        }}
                    />
                </FilterList>

                <FilterList
                    label="Patient Status"
                    icon={<PersonOutlineIcon />}
                >
                    <FilterListItem
                        label="Active"
                        value={{ is_discharged: false }}
                    />
                    <FilterListItem
                        label="Discharged"
                        value={{ is_discharged: true }}
                    />
                </FilterList>
    
                <FilterList
                    label="Missed Appointment"
                    icon={<MailIcon />}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{ 
                            statistics_missed: {
                                "!=": 0,
                            },
                        }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{ 
                            statistics_missed: 0,
                         }}
                    />
                </FilterList>
    
                <FilterList
                    label="App Usage Status"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Active"
                        value={{ app_usage_status: "Active" }}
                    />
                    <FilterListItem
                        label="Inactive"
                            value={{ app_usage_status: "Inactive" }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    )
};

export default Aside;
