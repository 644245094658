import React, { useState, useEffect } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Datagrid, FunctionField, List, Pagination, SelectInput, useDataProvider, useListContext } from 'react-admin';
import { Paper, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';
import { endOfToday, endOfYesterday } from 'date-fns';
import moment from 'moment-timezone';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#00302B',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

const CustomPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const getAdherenceColor = (value) => {
    if (value === -1) {
        return 'gray'
    } else if (value >= 80) {
        return 'green'
    } else if (value <= 50) {
        return 'red'
    }

    return 'black'
}

const UserList = ({isLoading, setIsLoading}) => {
    const { data, isFetching } = useListContext()
    const navigate = useNavigate()

    useEffect(() => {
        const selectedPractice = sessionStorage.getItem('selectedPractice')
        if (!isLoading && isFetching && (!data || data.length === 0 || data[0].practice.id !== selectedPractice)) {
            setIsLoading(true)
        } else if (isLoading && !isFetching) {
            setIsLoading(false)
        }
    }, [isLoading, data, isFetching])

    return (
        <Datagrid
            bulkActionButtons={false}
            sx={{
                '& .RaDatagrid-headerCell': {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                },
                '& .MuiTableCell-root': {
                    borderBottom: 'none',
                }
            }}
            isLoading={isLoading}
        >
            <FunctionField
                label="Patient"
                render={(record) => {
                    return (
                        <span
                            style={{
                                cursor: 'pointer',
                                color: '#007AFF',
                                textDecoration: 'underline',
                            }}
                            onClick={(event) => {
                                Mixpanel.track('PROV.Dashboard.AppointmentPROsList.Click', { userId: record.id })
                                navigate(`/users/${record.id}/patient-messages`)
                            }}
                        >
                            {`${record.first} ${record.last}`}
                        </span>
                    )
                }}
            />
            <FunctionField
                label="Appt Time"
                render = {(record) => {
                    if (!record || !record.statistics_next_visit) return null

                    const tz = sessionStorage.getItem('selectedPracticeTZ') || moment.tz.guess()
                    const formattedTime = moment.unix(record.statistics_next_visit).tz(tz).format('h:mm A')

                    return <span>{formattedTime}</span>
                }}
            />
            <FunctionField
                label="Overall"
                render={(record) => {
                    const surveys = record.surveys
                    if (!surveys || surveys.length === 0) {
                        return <span>-</span>
                    }
                    
                    const score = surveys[surveys.length - 1].since_start

                    let color = 'gray'
                    let value = "SAME"
                    if (score > 0) {
                        color = 'red'
                        value = "WORSE"
                    } else if (score < 0) {
                        color = 'green'
                        value = "BETTER"
                    }

                    return <span style={{ color, fontWeight: 'bold' }}>{value}</span>
                }}
            />
            <FunctionField
                label="Recent"
                render={(record) => {
                    if (!record.statistics_recent_feedback) {
                        return <span>-</span>
                    }

                    let color = 'gray'
                    if (record.statistics_recent_feedback === "BETTER") {
                        color = 'green'
                    } else if (record.statistics_recent_feedback === "WORSE" || record.statistics_recent_feedback.includes("PAIN")) {
                        color = 'red'
                    }

                    return <span style={{ color, fontWeight: 'bold' }}>{record.statistics_recent_feedback}</span>
                }}
            />
            <FunctionField
                label={
                    <Typography component="span" sx={{ fontWeight: 'bold' }}>
                        Adherence
                        <br />
                        CV / HEP
                    </Typography>
                }
                render={(record) => {
                    return (
                        <Typography component="span">
                            <span style={{ color: getAdherenceColor(record.statistics_appt_adherence) }}>
                                {record.statistics_appt_adherence === -1 ? '-' : `${record.statistics_appt_adherence}%`}
                            </span>
                            {" / "}
                            <span style={{ color: getAdherenceColor(record.statistics_hep_adherence) }}>
                                {record.statistics_hep_adherence === -1 ? '-' : `${record.statistics_hep_adherence}%`}
                            </span>
                        </Typography>
                    )
                }}
            />
        </Datagrid>
    )
}

export default function App(props) {
    const dataProvider = useDataProvider()
    const [options, setOptions] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)

    // restore previous filter value
    const storedDoctor = sessionStorage.getItem('apptPROsDoctor')

    const [filter, setFilter] = useState<any>({
        practice: sessionStorage.getItem('selectedPractice'),
        statistics_next_visit: {
            //">=": Math.round(Math.floor(Date.now() / 1000)),
            ">=": Math.round(new Date(endOfYesterday()).getTime()/1000),
            "<=": Math.round(new Date(endOfToday()).getTime()/1000),
        },
        ...(storedDoctor ? { statistics_next_provider: storedDoctor } : {})
    })

    useEffect(() => {
        dataProvider.getList('providers', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: { practice: sessionStorage.getItem('selectedPractice') }
        }).then(({ data }) => {
            setOptions(data.map(provider => ({ id: provider.id, name: provider.name })))
        }).catch(error => console.error(error))
    }, [dataProvider])

    return (
        <Paper
            className="appointment-pros-list"
            elevation={3}
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: '100%',
                minHeight: '572px',
                position: 'relative'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                {/* Title */}
                <div style={{ display: 'flex', alignItems: 'center', maxWidth: '40%' }}>
                    <Typography variant='h5' fontWeight='bold' style={{ marginRight: 4 }}>
                        Today's Appointment PROs
                    </Typography>
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            {"Below is your list of scheduled patients today."}
                            <br /><br />
                            {"Review their PRO’s and Adherence Scores to prepare for their visit and/or message them."}
                        </React.Fragment>
                        }
                    >
                        <InfoOutlined fontSize='medium' style={{ opacity: 0.6 }} />
                    </HtmlTooltip>
                </div>

                {/* Filters */}
                <div style={{ 
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    flexWrap: 'wrap',
                }}>
                    {/* Doctor Filter */}
                    <FormControl size="small" sx={{ width: '200px' }}>
                        <InputLabel>Doctor</InputLabel>
                        <Select
                            value={filter.statistics_next_provider || ''}
                            onChange={(event) => {
                                let newFilter = {...filter}
                                if (event.target.value) {
                                    newFilter.statistics_next_provider = event.target.value
                                    sessionStorage.setItem('apptPROsDoctor', event.target.value)
                                } else if (newFilter.statistics_next_provider) {
                                    delete newFilter.statistics_next_provider
                                    sessionStorage.removeItem('apptPROsDoctor')
                                }
                                setFilter(newFilter);
                            }}
                        >
                            <MenuItem value="">All</MenuItem>
                            {options.map((option) => (
                                <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div>
                <List
                    resource="users"
                    filter={filter}
                    perPage={10}
                    pagination={<CustomPagination />}
                    exporter={false}
                    component="div"
                    sort={{ field: 'statistics_next_visit', order: 'ASC' }}
                    queryOptions={{ refetchOnWindowFocus: false }}
                    disableSyncWithLocation
                >
                    <UserList isLoading={isLoading} setIsLoading={setIsLoading} />
                </List>
            </div>
        </Paper>
    );
};