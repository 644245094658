import {
    Button,
    FormDataConsumer,
    TextInput,
    fetchUtils,
    useNotify,
} from "react-admin";
import { Grid } from '@mui/material';
import tokenManager from "../../tokenManager";
import { convert } from 'html-to-text'
import '../../scss/components/enhancedmessaging/SendTestMessage.scss';

const SendTestMessage = ({ origin }) => {
    const notify = useNotify();

    const plainText = (html: string) => {
        let text = convert(html.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), { wordwrap: false });
        return text.replace(/[\n]{2}/g, '\n').replace(/[\n]{2,}/g, '\n\n').trim();
    }

    const submitTestMessage = async (data: any, mode: string) => {

        let apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        apiUrl += '/kaizenovate/communication/1.0.0/send-test-message';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        let testMessageBody = {
          practice_id: sessionStorage.getItem('selectedPractice') || '',
          channel: mode
        }
        if (testMessageBody.channel === 'sms') {
          if (!data.test_sms) {
            // ERROR CASE
            notify('Unable to send test sms. No phone number provided', {type: 'error'})
            return
          }
          testMessageBody["phone"] = data.test_sms
        } else {
          if (!data.test_email) {
            // ERROR CASE
            notify('Unable to send test email. No email address provided', {type: 'error'})
            return
          }
          testMessageBody["email"] = data.test_email
        }

        if (origin === 'messagetemplates') {
          if (testMessageBody.channel === 'sms') {
            if (!data.sms_message) {
              // ERROR CASE
              notify('Unable to send test sms. No sms message provided', {type: 'error'})
              return
            }
            testMessageBody["custom_message"] = plainText(data.sms_message);
          } else {
            if (data.email_message) {
              testMessageBody["custom_message"] = data.email_message
            } else if (data.sms_message) {
              let email_message = convert(data.sms_message, { wordwrap: false });
              testMessageBody["custom_message"] = email_message.replace(/[\n]{2}/g, '\n').replace(/[\n]{2,}/g, '\n\n').trim();
            } else {
              // ERROR CASE
              notify('Unable to send test email. No message provided', {type: 'error'})
              return
            }
          }
        } else if (origin === "automation") {
          if (!data.template) {
            // ERROR CASE
            notify('Unable to send test message. No template provided', {type: 'error'})
            return
          }
          testMessageBody["template_id"] = data.template.hasOwnProperty('id') ? data.template.id : data.template
        } else if (origin === "broadcast") {
          if ((!data.use_template && !data.custom_message) || (data.use_template && !data.template)) {
            // ERROR CASE
            notify('Unable to send test message. No message provided', {type: 'error'})
            return
          }
          if (data.use_template) {
            testMessageBody["template_id"] = data.template.hasOwnProperty('id') ? data.template.id : data.template
          } else {
            if (testMessageBody.channel === 'sms') {
              testMessageBody["custom_message"] = plainText(data.custom_message);
            } else {
              testMessageBody["custom_message"] = data.custom_message
            }
          }
        } else if (origin === "provider-notification-create") {
          if (!data.custom_message) {
            // ERROR CASE
            notify('Unable to send test message. No message provided', {type: 'error'})
            return
          }
          if (testMessageBody.channel === 'sms') {
            testMessageBody["custom_message"] = plainText(data.custom_message);
          } else {
            testMessageBody["custom_message"] = data.custom_message;
          }
        } else if (origin === "provider-notification-edit") {
          const custom_message = data.trigger_data.provider_notification.custom_message;
          if (!custom_message) {
            // ERROR CASE
            notify('Unable to send test message. No message provided', {type: 'error'})
            return
          }
          if (testMessageBody.channel === 'sms') {
            testMessageBody["custom_message"] = plainText(custom_message);
          } else {
            testMessageBody["custom_message"] = custom_message;
          }
        }

        const response = await httpClient(`${apiUrl}`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(testMessageBody),
        }).catch((e)=> {
            console.log(`Error in submitTestMessage()`)
            console.error(e)
            notify('Unable to send test message', {type: 'error'})
            return
            // throw e
        })

        if (response) {
            notify('Test message sent', {type: 'info'})
        }

        console.log(response)
    }

    return (
        <div className='test-message'>
            <FormDataConsumer>
                {({ formData }) =>
                    <div className='test-message-form'>
                        <h4>Send a Test Message</h4>
                        <Grid container rowSpacing={1} mb={1} columnSpacing={1} sx={{width: "50%"}}>
                            <Grid item xs={7} md={8}>
                                <TextInput source='test_sms' label='Mobile number' fullWidth/>
                            </Grid>
                            <Grid item xs={5} md={4}>
                                <Button
                                    label='Send SMS'
                                    size='medium'
                                    onClick={()=> submitTestMessage(formData, 'sms')}
                                    variant='contained'
                                    disabled={!formData.test_sms}
                                />
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={1} mb={1} columnSpacing={1} sx={{width: "50%"}}>
                            <Grid item xs={7} md={8}>
                                <TextInput source='test_email' label='Email address' fullWidth type="email"/>
                            </Grid>
                            <Grid item xs={5} md={4}>
                                <Button
                                    label='Send Email'
                                    size='medium'
                                    onClick={()=> submitTestMessage(formData, 'email')}
                                    variant='contained'
                                    disabled={!formData.test_email}
                                />
                            </Grid>
                        </Grid>
                    </div>
                }
            </FormDataConsumer>
        </div>
    )
}

export default SendTestMessage;