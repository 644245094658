import { Grid } from "@mui/material";
import { useState } from "react";
import {
    BooleanInput,
    Confirm,
    FormDataConsumer,
    TextInput,
} from "react-admin";
import { useWatch, useFormContext } from 'react-hook-form';
import QueryBuilder from '../../components/enhancedmessaging/QueryBuilder';

const ConfirmAdvancedQuery = () => {
    const [open, setOpen] = useState(false);
    const formValues = useWatch();
    const formContext = useFormContext();
    const advancedMode = formValues.advanced_query_enabled;
    // if editing and already on advanced mode, ignore modal
    const [hasOpened, setHasOpened] = useState(advancedMode);

    if (advancedMode && !hasOpened) {
        setOpen(true);
        setHasOpened(true);
    }

    let query = formValues.selection_criteria;

    if (advancedMode) {
        if (query && typeof query === 'object') {
            if (formValues.selection_criteria_with_error) {
                formContext.setValue('selection_criteria', formValues.selection_criteria_with_error)
                formContext.setValue('selection_criteria_with_error', undefined);
            } else {
                query = JSON.stringify(query, null, 4);
                formContext.setValue('selection_criteria', query)
            }
        }
    } else {
        if (query && typeof query === 'string') {
            try {
                query = JSON.parse(query);
                formContext.setValue('selection_criteria', query)
            } catch (e) {
                console.error('Invalid JSON');
                formContext.setValue('selection_criteria', JSON.stringify({
                    combinator: 'and',
                    rules: [{
                        field: 'default',
                        id: '99999999-9999-9999-9999-999999999999',
                    }],
                }, null, 4));
                if (!formValues.selection_criteria_with_error) {
                    // set a "backup" of the invalid JSON so the user can edit it
                    formContext.setValue('selection_criteria_with_error', query);
                }
            }
        }
    }

    return (
        <Confirm
            isOpen={open}
            title='Warning! Advanced Feature!'
            className='broadcast-confirm-modal confirm-query'
            content={<>
                <p>
                    Selections entered here may not work as expected. Use only if you are familiar with the JSON format and the query structure.
                </p>
                <p>
                    If you undo this selection and edit the basic criteria, the advanced criteria will be lost.
                </p>
            </>}
            onClose={() => {
                formContext.setValue('advanced_query_enabled', false);
                setOpen(false)
            }}
            onConfirm={() => setOpen(false)}
            confirm='Continue'
        />
    );
};

const validateAdvancedQuery = (advancedQuery: any) => {
    if (!advancedQuery) return 'Required';
    try { // if valid JSON string
        if (advancedQuery) {
            const query = JSON.parse(advancedQuery);
            return validateQuery(query); // validate if it has a rule besides the default one
        } else {
            return 'Invalid JSON';
        }
    } catch (e) {
        return 'Invalid JSON';
    }
}

const validateQuery = (query: any) => {
    if (query && query.rules && query.rules.length > 0) {
        // invalid if the only rule is the default rule
        const rules = query.rules.filter((rule: any) => rule.field !== 'default');
        if (rules.length > 0) {
            const ruleEmptyValue = rules.filter((rule: any) => !rule.value);
            if (ruleEmptyValue.length > 0) return 'Rule value is required';
            else return undefined;
        } else {
            return 'Query needs at least one rule';
        }
    } else {
        return 'Query needs at least one rule';
    }
}

const SelectionCriteria = ({ mode }) => {
    return (
        <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
            <Grid item xs={6}>
                <p className='automation-label'>4. Selection criteria:</p>
            </Grid>
            <Grid item xs={6} sx={{marginTop: '4px', '& label': { marginLeft: 'auto' }, '& p': { display: 'none' }}}>
                <BooleanInput source='advanced_query_enabled' label='Advanced input' />
                <ConfirmAdvancedQuery />
            </Grid>
            <Grid item xs={12}>
                <FormDataConsumer>
                    {({ formData }) => {
                        const triggerId = mode === 'create' ? formData.trigger_id : formData.trigger?.id;
                        return formData.advanced_query_enabled ? (
                            <TextInput
                                source='selection_criteria'
                                fullWidth
                                multiline
                                className='textinput-multiline'
                                validate={()=>validateAdvancedQuery(formData.selection_criteria)}
                            />
                        ) : (<>
                            <QueryBuilder
                                triggerId={triggerId}
                                eventType={formData.type}
                            />
                            <TextInput
                                source='selection_criteria'
                                validate={() => validateQuery(formData.selection_criteria)}
                                // hide everything but the error message
                                sx={{ marginTop: '0', '& label': { display: 'none' }, '& div': { display: 'none' } }}
                                // set to QueryBuilder default value in the string format we use for selection_criteria
                                defaultValue={
                                    JSON.stringify({
                                        combinator: 'and',
                                        rules: [{
                                            field: 'default',
                                            id: '99999999-9999-9999-9999-999999999999',
                                        }],
                                    }, null, 4)
                                }
                            />
                            {mode === 'edit' && formData.type === "firstTrue" &&
                                <div>Note: making changes to existing criteria may cause patients to receive another message if they no longer qualify but qualify again in the future.</div>
                            }
                        </>)
                    }}
                </FormDataConsumer>
            </Grid>
        </Grid>
    );
}

export default SelectionCriteria;