import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Button } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import { makeGSM7Compliant } from "./helpers/gsm7ComplianceHelpers";

export const ConfirmSpecialText = ({formField}) => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);
    // remove special characters 
    const formValues = useWatch();
    const channels = formValues.channels;

    useEffect(() => {
        if (!hasOpened) {
            setOpen(true);
            setHasOpened(true);
        }
    }, [channels, hasOpened]);

    const formContext = useFormContext();
    const onCancel = () => {
        const newMessage = makeGSM7Compliant(formValues[formField]);
        formValues[formField] = newMessage;
        formContext.setValue(formField, newMessage);
        setOpen(false)
    }

    const onConfirm = () => {
        setOpen(false)
    }

    return (
       <Dialog open={open} onClose={onCancel} sx={{
                  "& .MuiDialog-paper": {
                    maxWidth: "465px", 
                    width: "100%", 
                    margin: "25px"
                  },
                }}>
                  <DialogTitle style={{  fontWeight: 'bold' }}>Switch to Special Character Mode?</DialogTitle>
                  <DialogContent >
                      <p>
                          Adding emojis or other special characters switches to "Special Character" mode for the SMS message. While Emojis and special characters are great for engaging patients,
                          it does mean the number of characters included in each credit goes from 160 down to 70 - and that can use up more of your SMS Credits if your message is more than 70 characters.
                      </p>
                      <p>So, what should we do with these “Special Characters”?</p>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'space-between', margin: '0px 15px 0px 15px' }}>
                      <Button variant="contained"  style={{minWidth: "100px"}} onClick={onCancel} color='error' sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textTransform: "none",
                              height: "50px",
                              width: "150px",
                              "& span": {
                                  margin: "0 auto"
                              }
                          }}>
                          <Typography>Remove</Typography>
                      </Button>
                      <Button variant="contained"  style={{minWidth: "100px"}} onClick={onConfirm} color="primary" sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textTransform: "none",
                              height: "50px",
                              width: "150px",
                              "& span": {
                                  margin: "0 auto"
                              }
                          }}>
                          <Typography>Keep</Typography>
                      </Button>
                  </DialogActions>
                  <DialogContent >
                      <div>
                          Have questions? <a href='https://kaizenovate.zendesk.com/hc/en-us/articles/33362429693467-Understanding-EMBODIconnect-SMS-Credit-Usage' target='_blank'>Click here for our support article.</a>
                      </div>
                  </DialogContent>
              </Dialog>
    );
}