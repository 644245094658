import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, Link, MenuItem, Select, TextField, Typography } from "@mui/material"
import PatientDetailsContainer from "./PatientDetailsContainer"
import MailLockOutlinedIcon from '@mui/icons-material/MailLockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import { useState } from "react";
import tokenManager from "../../../tokenManager";
import { useNotify, useRedirect, useRefresh } from "react-admin";

const PatientDetails = ({record}) => {

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [menuLoading, setMenuLoading] = useState(false);
    const [communicationsLoading, setCommunicationsLoading] = useState(false)
    const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);
    const [unblockDialogOpen, setUnblockDialogOpen] = useState(false);
    const [changeEHRDialogOpen, setChangeEHRDialogOpen] = useState(false);
    const [deleteLoginDialogOpen, setDeleteLoginDialogOpen] = useState(false);
    const [accountNumber, setAccountNumber] = useState("");
    const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';

    const sendPasswordResetEmail = () => {
        if (record.app_usage_status !== "Active" || record.auth0_email === "") {
            notify('Unable to send password reset email. This patient record has no associated account.', {type: 'error'})
            return
        }
        setMenuLoading(true)
        fetch(`${apiUrl}/kaizenovate/provider/patient-account/1.0.0/send-password-reset-email`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenManager.getToken()}`
            },
            body: JSON.stringify({ user_id: record.id }),
        })
        .then(response => {
          return response.json()
        })
        .then(data => {
            setMenuLoading(false)
            if (data.error_message) {
                console.error(data.error_message)
                switch (data.error_message) {
                  case 'noAccount':
                    notify('Unable to send password reset email. The current patient record has no associated app account', {type: 'error'})
                    break;
                  case 'currentUserNotFound':
                  default:
                    notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
                    break;
                }
                return
            }
            setPasswordResetDialogOpen(true)
        })
        .catch(error => {
            setMenuLoading(false)
            console.error(error)
            notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
        })

        return
    }

    const unblockAccount = () => {
        if (record.app_usage_status !== "Active" || record.auth0_email === "") {
            notify('This patient record has no associated account to unblock.', {type: 'error'})
            return
        }
        setMenuLoading(true)
        fetch(`${apiUrl}/kaizenovate/provider/patient-account/1.0.0/unblock-account`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenManager.getToken()}`
            },
            body: JSON.stringify({ user_id: record.id }),
        })
        .then(response => {
          return response.json()
        })
        .then(data => {
            setMenuLoading(false)
            if (data.error_message) {
                console.error(data.error_message)
                switch (data.error_message) {
                  case 'noAccount':
                    notify('Unable to unblock account. The current patient record has no associated app account', {type: 'error'})
                    break;
                  case 'currentUserNotFound':
                  default:
                    notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
                    break;
                }
                return
            }
            refresh()
            setUnblockDialogOpen(true)
        })
        .catch(error => {
            setMenuLoading(false)
            console.error(error)
            notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
        })

        return
    }

    const changeEHRAccount = (newAccountNumber) => {
        if (record.app_usage_status !== "Active" || record.auth0_email === "") {
            notify('This patient record has no associated account to migrate.', {type: 'error'})
            return
        }
        setMenuLoading(true)
        fetch(`${apiUrl}/kaizenovate/provider/patient-account/1.0.0/change-ehr-account`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenManager.getToken()}`
            },
            body: JSON.stringify({ user_id: record.id, new_account_number: newAccountNumber }),
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            setMenuLoading(false)
            if (data.error_message) {
                console.error(data.error_message)
                switch (data.error_message) {
                  case 'noAccount':
                    notify('Unable to change EHR account. The current patient record has no associated app account', {type: 'error'})
                    break;
                  case 'noMatch':
                  case 'destinationUserNotFound':
                    notify('Unable to change EHR account. The account number you provided was not found on any records that have the same name, birthday and phone.', {type: 'error'})
                    break;
                  case 'destinationAlreadyLinked':
                    notify('Unable to change EHR account. The account number you provided is already linked to another app account.', {type: 'error'})
                    break;
                  case 'currentUserNotFound':
                  default:
                    notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
                    break;
                }
                return
            }
            setChangeEHRDialogOpen(false)
            setAccountNumber("")
            notify('EHR account changed successfully.', {type: 'info'})
            redirect(`/users/${data.new_id}/patient-details`)
        })
        .catch(error => {
            setMenuLoading(false)
            console.error(error)
            notify('Sorry, we ran into an error while processing you request. Please try again later', {type: 'error'})
        })

        return
    }

    const deleteLogin = () => {
        if (record.app_usage_status !== "Active" || record.auth0_email === "") {
            notify('This patient record has no associated login to delete.', {type: 'error'})
            return
        }
        setMenuLoading(true)
        fetch(`${apiUrl}/kaizenovate/provider/patient-account/1.0.0/delete-login`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenManager.getToken()}`
            },
            body: JSON.stringify({ user_id: record.id }),
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            setMenuLoading(false)
            if (data.error_message) {
                console.error(data.error_message)
                switch (data.error_message) {
                  case 'noAccount':
                    notify('Unable to delete login. The current patient record has no associated app account', {type: 'error'})
                    break;
                  case 'currentUserNotFound':
                  default:
                    notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
                    break;
                }
                return
            }
            refresh()
            setDeleteLoginDialogOpen(false)
            notify('Login successfully deleted.', {type: 'info'})
        })
        .catch(error => {
            setMenuLoading(false)
            console.error(error)
            notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
        })

        return
    }

    const toggleFeedbackEnabled = async (event, user_id, type) => {
        setCommunicationsLoading(true)

        fetch(`${apiUrl}/kaizenovate/provider/patient-account/1.0.0/toggle-communication-status`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenManager.getToken()}`
            },
            body: JSON.stringify({
                user_id: user_id,
                communication_type: type,
                enabled: event.target.value === 'true' ? true : false
            }),
        })
        .then(response => {
            console.log(response)
            return response.json()
        })
        .then((data) => {
            if (data.error_message) {
                console.error(data.error_message)
                setCommunicationsLoading(false)
                notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
                return
            }
            setCommunicationsLoading(false)
            refresh()
        })
        .catch((e)=> {
            console.log(`Error in patientDetails:toggleFeedbackEnabled()`)
            console.error(e)
            setCommunicationsLoading(false)
            notify('Sorry, we ran into an error while processing you request. Please try again later.', {type: 'error'})
            return
        })
    }

    return (
        <PatientDetailsContainer
            title="Patient Details"
            showMenu={true}
            menuOptions={[
              {
                label: (<span>Send Password Reset Email</span>),
                icon: (<MailLockOutlinedIcon sx={{ marginRight: 1}} />),
                onClick: sendPasswordResetEmail
              },
              {
                label: (<span>Unblock Account</span>),
                icon: (<LockOpenIcon sx={{ marginRight: 1}} />),
                onClick: unblockAccount
              },
              {
                label: (<span>Change EHR Account</span>),
                icon: (<SyncAltIcon sx={{ marginRight: 1}} />),
                onClick: (() => setChangeEHRDialogOpen(true))
              },
              {
                label: (<span style={{ color: 'red' }}>Delete Login</span>),
                icon: (<PersonRemoveOutlinedIcon sx={{ color: 'red', marginRight: 1}} />),
                onClick: (() => setDeleteLoginDialogOpen(true))
              }
            ]}
            menuLoading={menuLoading}
        >
            {/* Password Reset Dialog */}
            <Dialog
                open={passwordResetDialogOpen}
                onClose={() => setPasswordResetDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', fontWeight: 'bold'}}>
                {"Password Reset Email Sent"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"We have sent a password reset email to "}
                        <Typography component="span" fontWeight="bold">
                            {record.auth0_email}
                        </Typography>
                        {" and they should receive it within the next couple of minutes. If they don't see the email, please ask them to check their spam folder."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPasswordResetDialogOpen(false)} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Unblock Account Dialog */}
            <Dialog
                open={unblockDialogOpen}
                onClose={() => setUnblockDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', fontWeight: 'bold'}}>
                {"Unblock Successful"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line'}}>
                        {"We have unblocked the "}
                        <Typography component="span" fontWeight="bold">
                            {record.auth0_email}
                        </Typography>
                        {" account and they should now be able to log in with their previous password.\n\nIf they continue to have login issues, you can suggest that they reset their password."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUnblockDialogOpen(false)} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Login Dialog */}
            <Dialog
                open={deleteLoginDialogOpen}
                onClose={() => setDeleteLoginDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', fontWeight: 'bold'}}>
                {"Delete Login?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line'}}>
                        {"When a patient is having trouble logging in repeatedly, it may be helpful to have them create a new account.\n\nKeep in mind, none of the patient's information will be lost. This just lets them create a new account from scratch."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteLoginDialogOpen(false)} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={deleteLogin} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Change EHR Account Dialog */}
            <Dialog
                open={changeEHRDialogOpen}
                onClose={() => {setChangeEHRDialogOpen(false); setAccountNumber("")}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', fontWeight: 'bold'}}>
                {"Change EHR Account"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line'}}>
                        {"Switch to a different account when you have multiple accounts for one patient."}
                    </DialogContentText>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        placeholder="Enter New EHR Account Number"
                        fullWidth
                        value={accountNumber}
                        onChange={(event) => {setAccountNumber(event.target.value)}}
                    />
                    <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line'}}>
                        {"Please note: if you are switching to a new account that has not been used, your patient will go through onboarding for the new account."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setChangeEHRDialogOpen(false); setAccountNumber("")}} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => {changeEHRAccount(accountNumber)}} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            <Box display="flex" flexDirection="column" mb={3}>
                <Box display={{ xs: 'block', sm: 'flex' }} mb={3} mr={2}>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                        <Typography mb={1} className="c-grey">
                            App Usage
                        </Typography>
                        <Box fontWeight='fontWeightMedium'>
                            { (record ? record.app_usage_status : "") }
                        </Box>
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                        <Typography mb={1} className="c-grey">
                            Linked EHR Account
                        </Typography>
                        <Box fontWeight='fontWeightMedium'>
                            { (record ? record.external_id : "") }
                        </Box>
                    </Box>
                    <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                        <Typography mb={1} className="c-grey">
                            Login Email
                        </Typography>
                        <Typography
                            component={Link}
                            color="primary"
                            href={`mailto:${record.auth0_email}`}
                            style={{ textDecoration: 'none' }}
                        >
                            { record.auth0_email }
                        </Typography>
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                        <Typography mb={1} className="c-grey">
                            {`${record.app_usage_status === "Active" ? "Linked " : ""}Phone`}
                        </Typography>
                        <Box fontWeight='fontWeightMedium'>
                            {/* { (record.phone !== "" ? record.phone : (record.phone4 !== "" ? record.phone4 : record.phone1)) } */}
                            {record.phone ? record.phone.replace("+1", "") : (record.phone4 || record.phone1 || record.phone2 || record.phone3)}
                        </Box>
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                        <Typography mb={1} className="c-grey">
                            Account Status
                        </Typography>
                        <Box fontWeight='fontWeightMedium' sx={{ color: record.account_status === "Blocked" ? 'red' : 'primary'}}>
                            {record.account_status}
                        </Box>
                    </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }} mb={3} mr={2}>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} >
                        <Typography mb={1} className="c-grey">
                            Goal
                        </Typography>
                        <Box fontWeight='fontWeightMedium' mb={2} mr={2}>
                            {record.goal.goals.map((entry, index) => (
                                <p>{entry}</p>
                            ))}
                        </Box>
                        <Box fontWeight='fontWeightMedium' mb={2} mr={2}>
                            {record.goal.reminders.map((entry, index) => (
                                <p>{entry.day}: {entry.time}</p>
                            ))}
                        </Box>
                        <Box
                            fontWeight='fontWeightMedium'
                            mb={2}
                            mr={2}
                            sx={{
                              overflowWrap: 'break-word',
                              width: '175%',
                              whiteSpace: 'normal',
                            }}
                        >
                            <p>{record.goal.why}</p>
                        </Box>
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                        <Typography mb={1} className="c-grey">
                            Birthday
                        </Typography>
                        <Box fontWeight='fontWeightMedium'>
                            {record.birthday}
                        </Box>
                    </Box>
                    <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                        <Typography mb={1} className="c-grey">
                            EHR Email
                        </Typography>
                        <Typography
                            component={Link}
                            color="primary"
                            href={`mailto:${record.auth0_email}`}
                            style={{ textDecoration: 'none' }}
                        >
                            { record.email }
                        </Typography>
                    </Box>
                    <Box className="communication-preferences" flex={4} ml={{ xs: 0, sm: '0.5em' }} mr={{ xs: 0, sm: '1.0em' }}>
                        <Typography mb={1} className="c-grey">
                            Communication
                        </Typography>
                        <Box
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'min-content 1fr',
                                columnGap: '8px',
                                alignItems: 'center',
                            }}
                            mb={2}
                            mr={0}
                            mt={2}
                            sx={{ whiteSpace: 'nowrap'}}
                        >
                            <Box className="communication-label" fontWeight='fontWeightMedium' mb={2} mr={2}>
                                {"Appointment Reminders:"}
                            </Box>
                            <Select
                                className={`communication-select ${record.sms.accepted === true ? 'communication-enabled' : ''}`}
                                value={record.sms.accepted.toString()}
                                onChange={(event) => toggleFeedbackEnabled(event, record.id, 'accepted')}
                                disabled={communicationsLoading}
                            >
                                <MenuItem value={'true'}>Enabled</MenuItem>
                                <MenuItem value={'false'}>Disabled</MenuItem>
                            </Select>
                            <Box className="communication-label" fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ whiteSpace: 'nowrap'}}>
                                {"Two Way Messaging:"}
                            </Box>
                            <Select
                                className={`communication-select ${record.sms.twoWayMessaging === true ? 'communication-enabled' : ''}`}
                                value={record.sms.twoWayMessaging.toString()}
                                onChange={(event) => toggleFeedbackEnabled(event, record.id, 'twoWayMessaging')}
                                disabled={communicationsLoading}
                            >
                                <MenuItem value={'true'}>Enabled</MenuItem>
                                <MenuItem value={'false'}>Disabled</MenuItem>
                            </Select>
                            <Box className="communication-label" fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ whiteSpace: 'nowrap'}}>
                                {"App Notifications:"}
                            </Box>
                            <Select
                                className={`communication-select ${record.sms.appNotifications === true ? 'communication-enabled' : ''}`}
                                value={record.sms.appNotifications.toString()}
                                onChange={(event) => toggleFeedbackEnabled(event, record.id, 'appNotifications')}
                                disabled={communicationsLoading}
                            >
                                <MenuItem value={'true'}>Enabled</MenuItem>
                                <MenuItem value={'false'}>Disabled</MenuItem>
                            </Select>
                            <Box className="communication-label" fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ whiteSpace: 'nowrap'}}>
                                {"Email Reminders:"}
                            </Box>
                            <Select
                                className={`communication-select ${record.sms.emailReminders === true ? 'communication-enabled' : ''}`}
                                value={record.sms.emailReminders.toString()}
                                onChange={(event) => toggleFeedbackEnabled(event, record.id, 'emailReminders')}
                                disabled={communicationsLoading}
                            >
                                <MenuItem value={'true'}>Enabled</MenuItem>
                                <MenuItem value={'false'}>Disabled</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </PatientDetailsContainer>
    )
}

export default PatientDetails;
