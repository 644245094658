import { Typography } from "@mui/material";
import { Button } from "react-admin";
import { useFormContext } from "react-hook-form";
import { isGSM7Compliant, makeGSM7Compliant } from "./helpers/gsm7ComplianceHelpers";
import { useEffect, useState } from "react";
import { HtmlTooltip } from "./HtmlTooltip";
import { InfoOutlined } from "@mui/icons-material";

export const CustomCleanText = ({ formField, setNotGSMCompliantOnLoad, handleCleanPress }) => {
    const formContext = useFormContext();
    const [hasSpecialChars, setHasSpecialChars] = useState(false);

    useEffect(() => {
        if (formContext) {
            console.log(formContext.getValues(formField));
            const text = formContext.getValues(formField);
            if (!isGSM7Compliant(text)) {
                setHasSpecialChars(true);
            } else {
                setHasSpecialChars(false);
            }
        }
    }, [formContext]);
    const handleClick = () => {
        let text = formContext.getValues(formField);
        text = makeGSM7Compliant(text);
        formContext.setValue(formField, text);
        // this is a function that sets the state of the parent component to monitor if the text has been cleaned
        handleCleanPress(true)

    };

    return (
        hasSpecialChars && <span style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={handleClick}>
                <Typography style={{ fontSize: '16px' }}>Clean Text</Typography>
            </Button>
            <HtmlTooltip style={{ margin: "auto 0 auto 0px" }}
                title={
                    <>
                        <Typography color="black">
                            By Cleaning Text you will remove all emojis in your message and replace special characters with typical characters.
                        </Typography>
                    </>
                }
            >
                <InfoOutlined fontSize='small' />
            </HtmlTooltip>
        </span>
    );
}
