import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { 
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
 } from 'react-admin';


const ConditionsEdit = (props) => {

    return (
        <Edit 
            {...props}
            mutationMode='pessimistic'
        >
            <SimpleForm 
            {...props}
            // validate={validateForm}
            sx={{ width: '80%' }}
            >
                <Typography variant="h4" gutterBottom mb={3}>
                    <Box fontWeight='fontWeightBold'>
                        Edit Condition
                    </Box>
                </Typography>
                                <TextInput
                                    autoFocus
                                    source="name"
                                    fullWidth
                                    disabled={true}
                                />            
                                <TextInput
                                    source="diagnosis_codes"
                                    fullWidth
                                    multiline
                                />
                                <TextInput
                                    source="image"
                                    fullWidth
                                />
                                <Box flex={6} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        source="masterprogram_id"
                                        reference="masterprograms"
                                        filter={{ practice_id: "EMBODI" }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput optionValue="id" optionText="name" label="Protocol" source="name" fullWidth={false} />
                                    </ReferenceInput>
                                </Box>                                
            </SimpleForm>
        </Edit>
    );
};

export default ConditionsEdit;
