import * as React from 'react';
import { useRecordContext, useGetIdentity } from 'react-admin';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Avatar,
    Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { intervalToDuration, formatDuration } from 'date-fns';
import { logActivity } from '../../logActivity';
import { useEffect } from 'react';

const TableCellLeft = styled(TableCell)({ textAlign: 'left' });

const formatSeconds = (seconds) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const zeroPad = (num) => String(num).padStart(2, "0");
    const fSeconds = formatDuration(duration, {
        format: seconds >= 3600 ? ["hours", "minutes", "seconds"] : ["minutes", "seconds"],
        zero: true,
        delimiter: ":",
        locale: {
        formatDistance: (_token, count) => zeroPad(count)
        }
    });
    return fSeconds;
}

const BillingsEntries = () => {

    //date for automated testing, 0 if not testing
    var date = Number(sessionStorage.getItem('testDate'))
    if (!date) {
        date = 0
    }

    const record = useRecordContext();
    const { identity, isLoading: identityLoading } = useGetIdentity();

    var startTime = new Date();

    //add activity log when moving away from billing
    useEffect(() => {
        return () => {
            const duration = Math.round((new Date().getTime() - startTime.getTime())/1000);
            var newActivity = {
                "date": Math.round(Date.now()/1000),
                "duration": 0,
                "activity": "Viewed Billing Details",
                "first": identity?.firstName ?? "",
                "last": identity?.lastName ?? "",
                "email": identity?.username ?? "",
                "picture": identity?.picture ?? "",
                "comment": "None",
                "billable": false,
            };
            if (duration >= 1) {
                logActivity('PROVIDER.View.Billing', newActivity.activity, newActivity.comment, record.patient.id, newActivity.duration, date)
            }
        }
    }, [!identityLoading])

    if (!record) return null;
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                    <span style={{ fontWeight: 'bold' }}>Date</span>
                    </TableCell>
                    <TableCellLeft>
                    <span style={{ fontWeight: 'bold' }}>Duration</span>
                    </TableCellLeft>
                    <TableCellLeft>
                    <span style={{ fontWeight: 'bold' }}>Activity</span>
                    </TableCellLeft>
                    <TableCellLeft>
                    <span style={{ fontWeight: 'bold' }}>Provider</span>
                    </TableCellLeft>
                    <TableCellLeft>
                    <span style={{ fontWeight: 'bold' }}>Comment</span>
                    </TableCellLeft>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.log_entries.map((item: any) => (
                    <TableRow key={item.product_id}>
                        <TableCell>
                            {
                                item && Number.isInteger(item.date) && item.date !== 0 ? new Date(item.date * 1000).toLocaleString() : item.date
                            }
                        </TableCell>
                        <TableCellLeft>
                            {
                                item && item.data ? item.data['split'] ? 'Using ' + formatSeconds(item.duration) + ' out of ' + formatSeconds(item.data['totalDuration']) + ' total time for activity' : item && item.duration && formatSeconds(item.duration) : item && item.duration && formatSeconds(item.duration)
                            }
                        </TableCellLeft>
                        <TableCellLeft>
                            {
                                item && item.subject
                            }
                        </TableCellLeft>
                        <TableCellLeft>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '3%' }}>
                                <Avatar
                                    src={item.image}
                                    style={{ width: 25, height: 25 }}
                                />
                                {
                                    item && item.log_name
                                }
                            </Box>
                        </TableCellLeft>
                        <TableCellLeft>
                            {
                                item && item.comment
                            }
                            {
                                item && item.data && item.data['note'] ? " - Note: " : ""
                            }
                            {
                                item && item.data && item.data['note']
                            }
                        </TableCellLeft>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default BillingsEntries;
