import { dynamicTextRanges } from "../../../segments/dynamicTextRanges";

export const dynamicTextHandling = (parsedMessage) => {
  const dynamicTextKeys = Object.keys(dynamicTextRanges);

  const dynamicTextUsage = dynamicTextKeys.reduce((acc, option) => {
    const count = (parsedMessage?.match(new RegExp(option, "g")) || []).length;
    if (count > 0) {
      acc[option] = count;
    }
    return acc;
  }, {});

  let estimationLengthMin = 0;
  let estimationLengthMax = 0;

  Object.keys(dynamicTextUsage).forEach((key) => {
    const count = dynamicTextUsage[key];
    const minLength = dynamicTextRanges[key]?.min || 0;
    const maxLength = dynamicTextRanges[key]?.max || 0;
    estimationLengthMin += count * minLength;
    estimationLengthMax += count * maxLength;
  });

  return { dynamicTextKeys, estimationLengthMin, estimationLengthMax };
};
