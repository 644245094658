import mixpanel from 'mixpanel-browser';
const projectToken = process.env.REACT_APP_MIXPANEL_TOKEN;
console.log(mixpanel)
mixpanel.init(projectToken);

let actions = {
    identify: (id) => {
      mixpanel.identify(id);
    },
    reset: () => {
      mixpanel.reset();
    },
    alias: (id) => {
      mixpanel.alias(id);
    },
    track: (name, props) => {
      let data = {...props}
      if (sessionStorage.getItem('selectedPracticeName')) {
        data.practice = sessionStorage.getItem('selectedPracticeName');
      }
      mixpanel.track(name, data);
    },
    people: {
      set: (props) => {
        mixpanel.people.set(props);
      },
    },
  };
  
  export let Mixpanel = actions;