import { Grid, Typography } from "@mui/material";
import RTMAnalysisChart98977 from '../components/dashboard/RTMAnalysisChart98977';
import RTMAnalysisChart98980 from '../components/dashboard/RTMAnalysisChart98980';
import AppEngagementFunnel from '../components/dashboard/AppEngagementFunnel';
import AppointmentPROsList from '../components/dashboard/AppointmentPROsList';
import KeyOpenTasksList from '../components/dashboard/KeyOpenTasksList';
import { useState } from "react";
import ResultingPatientList from "../components/dashboard/ResultingPatientList";
import '../scss/pages/Dashboard.scss';
import WeeklyNewPatients from "../components/dashboard/WeeklyNewPatients";

const Dashboard = () => {
  const [showResultingUsers, setShowResultingUsers] = useState(false)
  const [userQuery, setUserQuery] = useState({practice: sessionStorage.getItem('selectedPractice')});
  
  return (
    <Grid className="dashboard" container spacing={2} padding={2}>
      {/* Top Row - 5 Small Tiles */}
      {/* {[...Array(5)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
          <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="h6">Title {index + 1}</Typography>
            <Typography variant="h4">Value {index + 1}</Typography>
            <Typography variant="subtitle1">Subtitle {index + 1}</Typography>
          </Paper>
        </Grid>
      ))} */}

      {/* Top Row - Banner */}
      <Grid item xs={12}>
        <WeeklyNewPatients />
      </Grid>

      {/* Middle Row - Two Large Resource Lists */}
      <Grid item xs={12} md={6}>
        <KeyOpenTasksList />
      </Grid>
      <Grid item xs={12} md={6}>
        <AppointmentPROsList />
      </Grid>

      {/* Bottom Row - Charts */}
      <Grid item xs={12}>
        <Typography fontSize='18px' textAlign='center'>
          Want to know what to do? Click on any green area below to see patients that need encouragement.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <AppEngagementFunnel
          setUserQuery={setUserQuery}
          setShowResultingUsers={setShowResultingUsers}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <RTMAnalysisChart98977
          setUserQuery={setUserQuery}
          setShowResultingUsers={setShowResultingUsers}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <RTMAnalysisChart98980
          setUserQuery={setUserQuery}
          setShowResultingUsers={setShowResultingUsers}
        />
      </Grid>

      {/* Conditional User List */}
      {showResultingUsers && (
        <Grid item xs={12} md={12}>
          <ResultingPatientList
            userQuery={userQuery}
            onClose={() => {
              setShowResultingUsers(false)
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Dashboard;
