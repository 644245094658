import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import {
    useGetList,
    useGetMany,
    useCreate,
    useInput,
    useDataProvider,
    useNotify,
} from 'react-admin';
import { colors } from '../../segments/tagColors';


type TagListEditOptions = {
    reference: string;
    source: string;
    sourceType: string;
    label?: string;
    adminMode: boolean;
};

const TagListField = ({reference, source, sourceType, label, adminMode }: TagListEditOptions ) => {
    // console.log({ reference, source, sourceType, label })
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const { field } = useInput({ source });
    const [open, setOpen] = useState(false);
    const [newTagName, setNewTagName] = useState('');
    const [newTagColor, setNewTagColor] = useState(colors[0]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [localTags, setLocalTags] = useState(field.value || [])

    const { data: allTags, isLoading: isLoadingAllTags } = useGetList(reference, {
        filter: {
            practice_id: adminMode ? "EMBODI" : sessionStorage.getItem('selectedPractice'),
            object_type: sourceType,
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });
    const { data: tags, isLoading: isLoadingRecordTags } = useGetMany(
        reference,
        { ids: localTags },
        { enabled: false },
    );
    const [create] = useCreate();

    const unselectedTags =
        allTags && allTags.filter(tag => !localTags.includes(tag.id));

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteTag = (id: any) => {
        setLocalTags(localTags.filter((tagId: any) => tagId !== id));
    };

    const handleAddTag = (id: any) => {
        setLocalTags([...localTags, id]);
        setAnchorEl(null);
    };

    const handleOpenCreateDialog = () => {
        setOpen(true);
        setAnchorEl(null);
        setDisabled(false);
    };

    const handleNewTagNameChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setNewTagName(event.target.value);
    };

    const handleCreateTag = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setDisabled(true);
        dataProvider.getList('tags', {
            filter: {
                name: `"${newTagName}"`,
                object_type: sourceType,
                practice_id: adminMode ? "EMBODI" : sessionStorage.getItem('selectedPractice'),
            },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
        }).then(({ data }) => {
            if (data.length === 0) {
                create(
                    reference,
                    { data: { name: newTagName, color: newTagColor, object_type: sourceType, practice_id: adminMode ? "EMBODI" : sessionStorage.getItem('selectedPractice') } },
                    {
                        onSuccess: tag => {
                            setLocalTags([...localTags, tag.id]);
                            setNewTagName('');
                            setNewTagColor(colors[0]);
                            setOpen(false);
                        },
                    },
                );
            } else {
                notify(`A tag with this name already exists}`, {type: 'error'});
                setDisabled(false);
            }
        }).catch((e) => {
            console.error("Error creating tag in TagListField")
            console.error(e)
            notify("We ran into an error while trying to create the new tag. Please try again later.", {type: 'error'});
            setDisabled(false);
        })
    };

    useEffect(() => {
        field.onChange(localTags)
    }, [localTags])

    if (isLoadingRecordTags || !tags || isLoadingAllTags) return null;
    return (
        <>
        <Box mt={1} sx={{ minHeight: "68px"}}>
          {label && <Typography variant="body2" color="textSecondary" sx={{paddingBottom: "5px"}}>{label}</Typography> }
            {tags && tags.map(tag => (
                <>
                    <Chip
                        key={tag.id}
                        size="small"
                        variant="outlined"
                        onDelete={() => handleDeleteTag(tag.id)}
                        label={tag.name}
                        style={{ backgroundColor: tag.color, border: 0, marginBottom: "5px" }}
                    /> &nbsp;
                </>
            ))}
                <Chip
                    component="div"
                    icon={<ControlPointIcon />}
                    size="small"
                    variant="outlined"
                    onClick={handleOpen}
                    label="Add"
                    color="primary"
                    style={{ marginBottom: "5px" }}
                />
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                {unselectedTags?.map(tag => (
                    <MenuItem key={tag.id} onClick={() => handleAddTag(tag.id)}>
                        <Chip
                            size="small"
                            variant="outlined"
                            label={tag.name}
                            style={{
                                backgroundColor: tag.color,
                                border: 0,
                            }}
                            onClick={() => handleAddTag(tag.id)}
                        />
                    </MenuItem>
                ))}
                <MenuItem onClick={handleOpenCreateDialog}>
                    <Chip
                        icon={<EditIcon />}
                        size="small"
                        variant="outlined"
                        onClick={handleOpenCreateDialog}
                        color="primary"
                        label="Create new tag"
                    />
                </MenuItem>
            </Menu>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <form onSubmit={handleCreateTag}>
                    <DialogTitle id="form-dialog-title">
                        Create a new tag
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            label="Tag name"
                            fullWidth
                            value={newTagName}
                            onChange={handleNewTagNameChange}
                            sx={{ mt: 1 }}
                        />
                        <Box display="flex" flexWrap="wrap" width={230} mt={2}>
                            {colors.map(color => (
                                <RoundButton
                                    key={color}
                                    color={color}
                                    selected={color === newTagColor}
                                    handleClick={() => {
                                        setNewTagColor(color);
                                    }}
                                />
                            ))}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={disabled}
                            
                        >
                            Add tag
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            </Box>
        </>
    );
};

const RoundButton = ({ color, handleClick, selected }: { color: string, handleClick: () => void, selected: boolean }) => (
    <Box
        component="button"
        type="button"
        sx={{
            bgcolor: color,
            width: 30,
            height: 30,
            borderRadius: 15,
            border: selected ? '2px solid grey' : 'none',
            display: 'inline-block',
            margin: 1,
        }}
        onClick={handleClick}
    />
);

export default TagListField;