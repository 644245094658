export const dynamicTextRanges = {
  "{FirstName}": {
    min: 3,
    max: 21,
    average: 6,
    median: 6,
  },
  "{LastName}": {
    min: 2,
    max: 26,
    average: 6.7,
    median: 6,
  },
  "{LastAppointment}": {
    min: 27,
    max: 38,
    average: 32,
    median: 32, //?
  },
  "{NextAppointment}": {
    min: 27,
    max: 38,
    average: 32,
    median: 32, //?
  },
  "{PracticeName}": { // should probably be practice specific
    min: 13,
    max: 36,
    average: 22.2,
    median: 21.5,
  },
  "{PracticePhone}": {
    min: 12,
    max: 12,
    average: 12,
    median: 12,
  },
  "{PracticeAddress}": { // should probably be practice specific
    min: 33,
    max: 58,
    average: 46.2,
    median: 46,
  },
  "{ReviewURL}": { // should probably be practice specific
    min: 39,
    max: 321,
    average: 118.2,
    median: 79,
  },
  "{LastProviderName}": {
    min: 7,
    max: 27,
    average: 15,
    median: 15,
  },
  "{NextProviderName}": {
    min: 7,
    max: 27,
    average: 15,
    median: 15,
  },
  "{AppointmentDate}": {
    min: 18,
    max: 28,
    average: 23,
    median: 23, //?
  },
  "{AppointmentTime}": {
    min: 7,
    max: 8,
    average: 7.25,
    median: 7, //?
  },
  "{AppointmentType}": {
    min: 3,
    max: 31,
    average: 14,
    median: 13,
  },
  "{AppointmentDurationMinutes}": {
    min: 1,
    max: 3,
    average: 2,
    median: 2,
  },
  "{AppointmentProviderName}": {
    min: 7,
    max: 27,
    average: 15,
    median: 15,
  },
  "{AppointmentPracticeLocation}": { // should probably be practice specific
    min: 13,
    max: 36,
    average: 22.2,
    median: 21.5,
  },
  "{AppointmentPracticeAddress}": { // should probably be practice specific
    min: 33,
    max: 58,
    average: 46.2,
    median: 46,
  },
  "{AppointmentPracticePhone}": {
    min: 12,
    max: 12,
    average: 12,
    median: 12,
  },
  "{MessageType}": {
    min: 4,
    max: 6,
    average: 5,
    median: 5,
  },
  "{OptInStatus}": {
    min: 4,
    max: 5,
    average: 4.5,
    median: 4.5,
  },
  "{OptInType}": {
    min: 9,
    max: 15,
    average: 12.75,
    median: 13.5,
  },
  "{RTMCode}": {
    min: 5,
    max: 5,
    average: 5,
    median: 5,
  },
  "{Rating}": {
    min: 1,
    max: 1,
    average: 1,
    median: 1,
  },
  "{ConfirmationResponse}": {
    min: 9,
    max: 10,
    average: 9.5,
    median: 9.5,
  },
  "{ResponseLink}": {
    min: 44,
    max: 44,
    average: 44,
    median: 44,
  },
};