import React, { useState, useEffect } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import dataProvider from '../../dataProvider';
import Typography from '@mui/material/Typography';
import tokenManager from '../../tokenManager';
import { Mixpanel } from '../../mixpanel';

export default function WeeklyNewPatients(props) {
    
    const [data, setData] = useState<any>({});

    useEffect(() => {
        setTimeout(()=>{ // migrated functionality of "getApiData" to here to fix build warning
            if(tokenManager.getToken()) {
                dataProvider.get(`kaizenovate/provider/1.0.0/weekly_new_patients?practice_id=${sessionStorage.getItem('selectedPractice')}`).then(result => {
                    setData(result.data);
                });
            }
        }, 100)
    }, []);

    return (
        <div>
            {data.dates ? (
                <Paper
                    elevation={3}
                    sx={{
                        padding: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        gap: 5,
                        backgroundColor: data.newPatients > 0 ? data.onboardingRate >= data.targetRate ? "#EAF7EE" : "#FFEFEF" : 'white',
                    }}
                >
                    <Typography fontWeight='bold' >
                        {`${data.dates}: New Patients`}
                    </Typography>

                    <Typography>
                        <span style={{ fontWeight: 'bold' }}>{data.appOnboarded}</span>
                        {" out of "}
                        <span style={{ fontWeight: 'bold' }}>{data.newPatients}</span>
                        {" new patients have app onboarded this week!"}
                    </Typography>

                    <Typography>
                        {"Onboarding Rate: "}
                        <span
                            style={{
                                fontWeight: 'bold',
                                color: data.appOnboarded > 0 ? data.onboardingRate >= data.targetRate ? "#4C8A7E" : "#B50D0D" : 'black'
                            }}
                        >
                            {data.onboardingRate === -1 ? "-" : `${data.onboardingRate}%`}
                        </span>
                    </Typography>

                    <Typography>
                        {"Target Rate: "}
                        <span style={{ fontWeight: 'bold' }}>
                            {`${data.targetRate}%`}
                        </span>
                    </Typography>
                </Paper>
            ) : (
                <Paper
                    elevation={3}
                    sx={{
                        padding: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Typography>
                        Loading...
                    </Typography>
                </Paper>
            )}
        </div>
    );
}