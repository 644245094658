import { Grid } from '@mui/material';
import {
    BooleanInput,
    Create,
    required,
    SimpleForm,
    useNotify,
    useRedirect,
    TextInput,
    Button,
    Toolbar,
    SaveButton,
} from 'react-admin';
import { useEffect, useState } from 'react';
import '../../scss/pages/EnhancedMessaging.scss';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import SelectionCriteria from '../messageautomations/SelectionCriteria';
import transformAutomationsData from '../messageautomations/TransformAutomationsData';
import ScheduleCriteria from '../messageautomations/ScheduleCriteria';
import { RichTextInput } from 'ra-input-rich-text';
import { convert } from 'html-to-text';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

const transform = (data: any) => {
  data = {
    ...data,
    practice_id: sessionStorage.getItem('selectedPractice') || '',
    template_id: data.template,
    is_broadcast: false,
  };

  data = transformAutomationsData(data);

  // delete fields that are not in the model
  delete data.template; // "converted" to template_id

  return data;
}

const gridProps = {
  container: true,
  rowSpacing: 1,
  mb: 1,
  columnSpacing: 1
}

const editorOptions = { // Disable automatic URL linking
    extensions: [StarterKit, Link.configure({ autolink: false })]
}

const ProviderNotificationCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [emailDomains, setEmailDomains] = useState(sessionStorage.getItem('notificationEmailDomains')?.split(',') || [])
    console.log(emailDomains)

    const onSuccess = (data: any) => {
        notify('Notification created', { type: 'info' });
        redirect('/providernotifications'); 
    }

    const onCancel = (data: any) => {
        redirect('/providernotifications');
    }

    useEffect(() => { // move template <create> option to top on automation pages
        document.body.classList.add("provider-notifications-page");
        return () => {
            document.body.classList.remove("provider-notifications-page");
        }
    })

    const CustomToolbar = (props) => {
        return <Toolbar {...props} style={{display: "flex", justifyContent:"space-between"}}>
            <Button
                label='Cancel'
                size='medium'
                variant='contained'
                onClick={onCancel}
            />
            <SaveButton />
        </Toolbar>
    }

    return (
        <Create
            className="provider-notification-create"
            sx={{ maxWidth: '1020px' }}
            transform={transform}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='provider-notification'
                toolbar={<CustomToolbar />}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Create Notification</h1>
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: '28px' }}>
                        <BooleanInput source='enabled' label='Enabled' defaultValue={false} />
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <p className='provider-notification-label'>1. Name your notification:</p>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='provider-notification-label'>2. Enter distribution list:</p>
                    <Grid item xs={12}>
                        <p>{`Enter a comma separated list of emails to receive the notification. Emails must contain ${(emailDomains.length === 0) ? "" : ((emailDomains.length === 1) ? (emailDomains[0]) : (emailDomains.slice(0, -1).join(', ') + ", or " + emailDomains[emailDomains.length - 1]))} to be valid.`}</p>
                        <TextInput
                            source='provider_email_list'
                            fullWidth
                            validate={[required()]}
                            label='Distribution List'
                            multiline
                        />
                    </Grid>
                </Grid>

                <ScheduleCriteria mode='create' />

                <SelectionCriteria mode='create' />

                <Grid {...gridProps} className='provider-notification-richtext'>
                    <p className='provider-notification-label'>5. Enter the text of the notification:</p>
                    <Grid item xs={12}>
                        <p>Email Subject</p>
                        <RichTextInput
                            id="email-subject"
                            source="email_subject"
                            fullWidth
                            helperText = "Enter an email subject - dynamic text supported"
                            // defaultValue={formData? formData.name: ""} // not showing up when use existing template is picked after Electronic Mail box
                            validate={[required()]}
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            editorOptions={editorOptions}
                            parse={v => convert(v.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), { wordwrap: false })} // strip html off subject
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <p>Email Message</p>
                        <RichTextInput
                            source='custom_message'
                            fullWidth
                            validate={[required()]}
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            editorOptions={editorOptions}
                            // parse={v => convert(v, { wordwrap: false })} // strip html off message too since we aren't allowing extra formating here
                        />
                    </Grid>
                </Grid>
                <SendTestMessage origin='provider-notification-create' />
            </SimpleForm>
        </Create>
    )
}

export default ProviderNotificationCreate;
