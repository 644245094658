const tokenManager = () => {
//    const logoutEventName = 'kaizenovate.net.provider.app'
    // let localToken = null
    // let localRefresh = null - commented out for causing build errors
    let refreshTimeOutId
    let refreshToken

    const getToken = () => {
//        console.log(`Returning token ${localToken}`)
        return localStorage.getItem('token')
        //return localToken
    }

    const getRefresh = () => {
//        console.log(`Returning token ${localRefresh}`)
        return localStorage.getItem('localRefresh')
        //return localRefresh
    }

    const setToken = (token, refresh=false, delay=false) => {
//        console.log(`Setting token to ${token}, ${refresh}, ${delay}`)
        if (delay) {
            const bufferTime = delay * 0.15 * 1000
            console.log(`Delay: ${delay*1000}`)
            console.log(`Less buffer: ${bufferTime}`)
            refreshTimeOutId = window.setTimeout(
                refreshToken,
                (delay * 1000) - bufferTime
            )
        }
        localStorage.setItem('token', token)
        //localToken = token
        localStorage.setItem('localRefresh', refresh)
        //localRefresh = refresh
        return true
    }

    const stopTokenRefreshes = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId)
        }
    }

    const setTokenRefreshCallback = (callback) => {
//        console.log(`Setting callback to a function`, callback)
        refreshToken = callback
    }

    const eraseToken = () => {
        console.log(`Erasing token`)
        localStorage.removeItem('token')
        //localToken = null
        localStorage.removeItem('localRefresh')
        //localRefresh = null
        stopTokenRefreshes()
//        window.sessionStorage.setItem(logoutEventName, Date.now())
        return true
    }

    // listens for logoutEvent in another tab
    /*
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            console.log(`received notification of logout in another tab - so logging out`)
            eraseToken()
        }
    })
    */

    return {
        eraseToken,
        getToken,
        setToken,
        getRefresh,
        setTokenRefreshCallback,
    }
}

export default tokenManager();