import { Typography, Grid, Paper, Box, Button, IconButton } from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { List, Pagination, ReferenceField, useListContext, useNotify } from "react-admin";
import { useNavigate } from "react-router-dom";
import dataProvider from "../../dataProvider";
import { Mixpanel } from "../../mixpanel";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

const CustomPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const UserGrid = () => {
    const { data, isLoading, isFetching } = useListContext()
    const [previousData, setPreviousData] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoading && !isFetching && data !== previousData) {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
            setPreviousData(data)
        }
    }, [isLoading, isFetching, data, previousData])

    if (isLoading || isFetching) return (<p>Loading...</p>)

    return (
        <Box sx={{ overflowX: "auto", whiteSpace: 'nowrap', padding: 2 }}>
            <Grid container spacing={2} sx={{ flewWrap: 'nowrap' }}>
                {data?.map((user) => (
                    <Grid item xs={6} sm={4} md={2} key={user.id} >
                        <div
                            style={{
                                display: 'block',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                cursor: 'pointer',
                                color: '#007AFF',
                                textDecoration: 'underline',
                            }}
                            onClick={(event) => {
                                Mixpanel.track('PROV.Dashboard.ResultingPatientList.PatientDetails', { userId: user.id })
                                window.scrollTo(0, 0)
                                navigate(`/users/${user.id}/patient-details`)
                            }}
                        >
                            {/* <ReferenceField record={user} source="id" reference="users" /> */}
                            <Typography variant='body2' noWrap>{user.first + ' ' + user.last}</Typography>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

const ResultingPatientList = ({userQuery, onClose}) => {
    const [sendBroadcastLoading, setSendBroadcastLoading] = useState(false)
    const navigate = useNavigate()
    const notify = useNotify()

    const handleSendBroadcast = async () => {
        setSendBroadcastLoading(true)
        Mixpanel.track('PROV.Dashboard.ResultingPatientList.SendBroadcast')

        try {
            const { data } = await dataProvider.getList('users', {
                filter: userQuery,
                pagination: {
                    page: 1,
                    perPage: 20000
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                }
            })

            if (!data) {
                notify('No users found', {type: 'warning'})
                return
            }
            const ids = data.map(user => user.id)
            navigate('/messagebroadcasts/create', { state: { selectedUserIds: ids } })
        } catch(e) {
            notify('We ran into an error, please try again later', {type: 'error'})
            console.error(e)
        } finally {
            setSendBroadcastLoading(false)
        }
    }

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h5' fontWeight='bold' style={{ marginRight: 4 }}>
                        Resulting Patient List
                    </Typography>
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <Typography color='inherit'>Resulting Patient List</Typography>
                            <br />
                            {"Patients in the selected group."}
                        </React.Fragment>
                        }
                    >
                        <InfoOutlined fontSize='medium' style={{ opacity: 0.6 }} />
                    </HtmlTooltip>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {(localStorage.getItem('isConnectUser') === 'true' && sessionStorage.getItem('embodiConnectEnabled') === 'true') && (
                      <Button variant="contained" style={{ marginRight: '8px' }} onClick={handleSendBroadcast} disabled={sendBroadcastLoading} >
                          {sendBroadcastLoading ? 'Loading...' : 'Send Broadcast'}
                      </Button>
                    )}
                    <IconButton
                        onClick={() => {
                            Mixpanel.track('PROV.Dashboard.ResultingPatientList.Close')
                            onClose()
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <div>
                <div style={{ margin: 'auto' }}>
                    <List
                        resource="users"
                        filter={userQuery}
                        perPage={60}
                        pagination={<CustomPagination />}
                        exporter={false}
                        component="div"
                        queryOptions={{ refetchOnWindowFocus: false }}
                        disableSyncWithLocation
                    >
                        <UserGrid />
                    </List>
                </div>
            </div>
        </Paper>
    )
}

export default ResultingPatientList;