import { Box, Grid, Typography } from '@mui/material';
import { 
    Button,
    Create,
    NumberInput,
    SelectInput,
    TextInput,
    SimpleForm,
    ReferenceInput,
    Toolbar,
    SaveButton,
} from 'react-admin';

const CustomToolbar = ( onCancel ) => {
  return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
      <Button
          label='Cancel'
          size='medium'
          variant='contained'
          onClick={onCancel.onCancel}
      />
      <SaveButton />
  </Toolbar>
}

const ExerciseCreate = ({ onCancel, onSuccess, user_id, condition_id, addAfterExercise }) => {

    console.log("Add After Exercise")
    console.log(addAfterExercise);

    const transform = (data) => {
        switch (data.display_level) {
            case '1 - Relieve':
                data.level = 'relieve';
                break;
            case '2 - Restore':
                data.level = 'restore';
                break;
            case '3 - Reinforce':
                data.level = 'reinforce';
                break;
        }

        data.sequence = addAfterExercise.sequence + 1

        return data
    }

    return (
        <Create 
            resource='exercises'
            mutationOptions={{ onSuccess: onSuccess }}
            transform={transform}
        >
            <SimpleForm 
            // validate={validateForm}
            toolbar={<CustomToolbar onCancel={onCancel}/>}
            >
                <Box pt={2} sx={{ width: '100%' }} mt={{ xs: 2, sm: 1 }}>
                    <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Details
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <SelectInput 
                                    isRequired
                                    fullWidth
                                    source="display_level"
                                    disabled={true}
                                    defaultValue={addAfterExercise.display_level}
                                    choices={[
                                        { id: '1 - Relieve', name: '1 - Relieve' },
                                        { id: '2 - Restore', name: '2 - Restore' },
                                        { id: '3 - Reinforce', name: '3 - Reinforce' },
                                    ]} />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        source="condition_id"
                                        reference="conditions"
                                        filter={{ custom_hep: false }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput 
                                            optionValue="id"
                                            optionText="name" 
                                            defaultValue={condition_id}
                                            source="id"
                                            disabled
                                            isRequired
                                            fullWidth
                                            label="Condition"
                                        />
                                    </ReferenceInput>
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <SelectInput 
                                        isRequired
                                        fullWidth
                                        source="status"
                                        emptyText={ "Active" } // Default values
                                        emptyValue={ 'active' } // Default values
                                        choices={[
                                            { id: 'active', name: 'Active' },
                                            { id: 'inactive', name: 'Inactive' },
                                        ]} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Easier
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        isRequired
                                        fullWidth
                                        source="easier_exercise.exercise"
                                        reference="exerciselibrarys"
                                        filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput optionValue="id" optionText="name" source="name" />
                                    </ReferenceInput>
                                </Box>
                                {/* <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        formData.easier_exercise.exercise123 = "test123";
                                        formData.easier_exercise.exercise = "test1235";
                                        console.log(formData.easier_exercise);
                                        return (
                                            <TextInput
                                                source={formData.easier_exercise.exercise}
                                                {...rest}
                                            />
                                        )
                                    }}
                                </FormDataConsumer>
                                <DynamicInput /> */}
                                {/* <ZipLookUp /> */}
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.sets"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.reps"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.rest"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="easier_exercise.hold"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="easier_exercise.instructions"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Default
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        isRequired
                                        fullWidth
                                        source="default_exercise.exercise"
                                        reference="exerciselibrarys"
                                        filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput optionValue="id" optionText="name" source="name" />
                                    </ReferenceInput>
                                </Box>
                                {/* <Link to="/exerciselibrarys">
                                    <Button 
                                        label="+ SELECT FROM LIBRARY"
                                    ></Button>
                                </Link> */}
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.sets"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.reps"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.rest"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="default_exercise.hold"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="default_exercise.instructions"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Harder
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        isRequired
                                        fullWidth
                                        source="harder_exercise.exercise"
                                        reference="exerciselibrarys"
                                        filter={{ practice_id: { "in": ["EMBODI", sessionStorage.getItem('selectedPractice')] } }}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput optionValue="id" optionText="name" source="name" />
                                    </ReferenceInput>
                                </Box>
                                {/* <Link to="/exerciselibrarys">
                                    <Button 
                                        label="+ SELECT FROM LIBRARY"
                                    ></Button>
                                </Link> */}
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.sets"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.reps"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.rest"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        source="harder_exercise.hold"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="harder_exercise.instructions"
                                        isRequired
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="hidden">
                            <TextInput
                                    disabled={true}
                                    source="patient_id"
                                    label="Patient Id"
                                    defaultValue={user_id}
                                />
                        </Grid>
                    </Grid>
                </Box>
            </SimpleForm>
        </Create>
    );
};

export default ExerciseCreate;
