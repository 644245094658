import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { 
    Create, 
    TextInput,
    SimpleForm, 
    ReferenceInput, 
    SimpleFormIterator, 
    ArrayInput, 
    SelectInput,
    NumberInput,
    Toolbar,
    Button,
    SaveButton,
    required,
    useNotify,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import VideoField from '../../components/exerciselibrarys/VideoField';
import { useState } from 'react';
import TagListField from '../../components/homeexercises/TagListField';




const CustomToolbar = ( onCancel ) => {
  return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
      <Button
          label='Cancel'
          size='medium'
          variant='contained'
          onClick={onCancel.onCancel}
      />
      <SaveButton />
  </Toolbar>
}

export const ExerciselibrarysCreateModal = ({ onCancel, onSuccess, record, adminMode, copyMode }) => {
    return <ExerciselibrarysCreateForm onCancel={onCancel} onSuccess={onSuccess} record={record} adminMode={adminMode} copyMode={copyMode} />;
}

const ExerciselibrarysCreateForm = ({ onCancel, onSuccess, record, adminMode, copyMode }) => {

    const modifiedRecord = {
        ...record,
        tags: [],
    }
    
    const [copyFromRecord, setCopyFromRecord] = useState(record)
    const [showVideo, setShowVideo] = useState(copyMode)
    const notify = useNotify();

    const getYouTubeVideoId = (url) => {
        try {
            const parsedUrl = new URL(url)
            const hostname = parsedUrl.hostname
            const isYouTubeDomain = hostname === 'www.youtube.com' || hostname === 'youtube.com' || hostname === 'youtu.be'

            if (!isYouTubeDomain) return false;

            if (hostname === 'youtu.be') {
                // Shortened URL format (e.g., https://youtu.be/dQw4w9WgXcQ)
                if (parsedUrl.pathname.length === 12) { // Video ID is 11 characters long
                    return parsedUrl.pathname.slice(1)
                } else {
                    return false
                }
            }

            if (hostname.includes('youtube.com')) {
                // Full YouTube URL format (e.g., https://www.youtube.com/watch?v=dQw4w9WgXcQ)
                const videoId = parsedUrl.searchParams.get('v');
                if (videoId && videoId.length === 11) { // Video ID is 11 characters long
                    return videoId
                } else {
                    return false
                }
            }

            return false;
        } catch (e) {
            console.error("Error validating youtube video link")
            console.error(e)
            return false
        }
    }

    const transform = (data) => {
        let newData = {...data}
        delete newData.id
        newData.created_by = adminMode ? "EMBODI" : sessionStorage.getItem('selectedPractice')

        if (adminMode) {
            newData.created_by = "EMBODI"
        } else {
            newData.created_by = sessionStorage.getItem('selectedPractice')
            if (!copyFromRecord || newData.video !== copyFromRecord.video) {
                // verify it is youtube link and set newData.thumbnail
                const videoId = getYouTubeVideoId(newData.video)
                if (!videoId) {
                    notify("Custom videos must be valid YouTube video links", {type: 'error'})
                    throw new Error("Custom videos must be valid YouTube video links")
                }

                newData.thumbnail = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`
            }
        }
        return newData
    }

    return (
        <Create
            resource="exerciselibrarys"
            mutationOptions={{ onSuccess: onSuccess }}
            transform={transform}
        >
            <Box pt={2} sx={{ width: '100%' }} mt={{ xs: 2, sm: 1 }}>
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    className="exerciselibrarys"
                    toolbar={<CustomToolbar onCancel={onCancel} />}
                    record={modifiedRecord}
                >
                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={8}>
                            <TextInput source='name' isRequired fullWidth validate={required()} />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput 
                                isRequired
                                validate={required()}
                                fullWidth
                                source="exercise_type"
                                emptyText={ "Exercise" } // Default values
                                emptyValue={ 'exercise' } // Default values
                                choices={[
                                    { id: 'exercise', name: 'Exercise' },
                                    { id: 'stretch', name: 'Stretch' },
                                ]} />
                        </Grid>
                    </Grid>
                    {showVideo ? (
                        <>
                            <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                                <Grid item xs={8} mb={2}>
                                    <VideoField source="video" />
                                </Grid>
                                <Grid item xs={4} mt={2}>
                                    <Button
                                        variant='text'
                                        onClick={() => {setShowVideo(false)}}
                                        label='Replace with my own custom YouTube video.'
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                                <Grid item xs={12}>
                                    <TextInput source='video' isRequired validate={required()} fullWidth label='Video URL'/>
                                </Grid>
                            </Grid>
                            {adminMode ? (
                                <Grid container rowSpacing={1} mb={1}>
                                    <Grid item xs={12}>
                                        <TextInput source='thumbnail' isRequired validate={required()} fullWidth label='Thumbnail URL'/>
                                    </Grid>
                                </Grid>
                            ) : (null)}
                        </>
                    )}
                    <Grid container rowSpacing={1} mb={1}  columnSpacing={1}>
                        <Grid item xs={3}>
                            <NumberInput source='sets' isRequired validate={required()} defaultValue={3} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='rest' isRequired validate={required()} defaultValue={30} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='reps' defaultValue={10} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='hold' defaultValue={30} fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}  columnSpacing={1}>
                        <Grid item xs={6}>
                            <ArrayInput source="muscle_groups" isRequired validate={required()} label="Regions" defaultValue={['']}>
                                <SimpleFormIterator>
                                    <ReferenceInput
                                        source=""
                                        reference="musclegroups"
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput 
                                            optionValue="name"
                                            optionText="name"
                                            isRequired
                                            validate={required()}
                                        />
                                    </ReferenceInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                        <Grid item xs={6}>
                            <TagListField
                                source="tags"
                                sourceType="exerciselibrarys"
                                label="Tags"
                                reference="tags"
                                adminMode={adminMode}
                            />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Box>
        </Create>
    );
};
