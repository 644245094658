import { Grid } from '@mui/material';
import {
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    TextInput,
    Button,
    Toolbar,
    SaveButton,
    useCreateSuggestionContext,
} from 'react-admin';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import '../../scss/pages/EnhancedMessaging.scss';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import SaveIcon from '@mui/icons-material/Save';
import SelectionCriteria from './SelectionCriteria';
import RenderMessageTemplate from './RenderMessageTemplate';
import transformAutomationsData from './TransformAutomationsData';
import { MessageTemplateCreateModal } from '../messagetemplates/Create';
import ScheduleCriteria from './ScheduleCriteria';
import { ConfirmAutomationMultiCredit } from '../../components/smstransparency/ConfirmAutomationMultiCredit';

const transform = (data: any) => {
    data = {
        ...data,
        practice_id: sessionStorage.getItem('selectedPractice') || '',
        template_id: data.template,
        is_broadcast: false,
    };

    data = transformAutomationsData(data);

    // delete fields that are not in the model
    delete data.template; // "converted" to template_id

    return data;
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

const CreateTemplateModal = () => {
    const { onCancel } = useCreateSuggestionContext();

    return (
        <Modal
            open={true}
            onClose={onCancel}
            className='automations-template-modal'
        >
            <MessageTemplateCreateModal />
        </Modal>
    );
};

/* Start template selection functions */
const FilteredTemplates = ({ event_types }) => {
    if (!event_types) event_types = ['General'];
    const valid_event_types = useWatch().valid_event_types;
    // if valid_event_types is not set or is set and not matching the current event_types, set it to current event_types
    const setValid = (!valid_event_types || (valid_event_types && valid_event_types.join() !== event_types.join()));

    return (<>
        {/* workaround for conditional useFormContext hook call */}
        {setValid && <SetValidEventType event_types={event_types} />}
        <ReferenceInput
            source='template'
            reference='messagetemplates'
            filter={{ is_broadcast: false, is_provider_notification: false, event_type: event_types }}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <AutocompleteInput
                optionText='name'
                label='Select a template...'
                filterToQuery={(value) => ({ name: value })}
                validate={[required()]}
                createLabel='< Create New Template >'
                createItemLabel="< Create New Template >"
                create={<CreateTemplateModal />}
            />
        </ReferenceInput>
    </>);
}

const GetTriggerAndFilter = ({ triggerId }) => {
    const record = useGetOne('triggers', { id: triggerId }).data;
    let event_filter = ['General'];
    if (record && record.category) event_filter.push(record.category);
    return <FilteredTemplates event_types={event_filter} />;
}

const SetValidEventType = ({ event_types }) => {
    useFormContext().setValue('valid_event_types', event_types);
    return null;
}
/* End template selection functions */

const CustomChannelInput = ({setIncludesSMSChannel}) => {
    const { setValue } = useFormContext();
    const { channels } = useWatch();
    const setChannels = (value) => setValue('channels', value);

    useEffect(() => {
        if (channels && channels.includes('sms')) {
            setIncludesSMSChannel(true);
        } else {
            setIncludesSMSChannel(false);
       
        }
    }, [channels])

    return <CheckboxGroupInput
        source='channels'
        label=''
        choices={[
            { id: 'sms', name: 'Text Message' },
            { id: 'email', name: 'Electronic Mail' },
        ]}
        validate={[required()]}
        defaultValue={['sms']}
        onChange={setChannels}
    />
}

const CustomToolbar = ({ onCancel, isMultiCredit, multiCreditNotify, setMultiCreditNotify, includesSMSChannel, submitBtn }) => {

    return <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Automations just needs a multi credit notification here */}
        <Button
            label='Cancel'
            size='medium'
            variant='contained'
            onClick={onCancel}
        />
        <Button
            label="Save"
            color='primary'
            variant='contained'
            size='medium'
            startIcon={<SaveIcon />}
            onClick={(event) => {
                event.preventDefault()
                // if the message could be multi credit but channel does not include sms just submit
                if (!includesSMSChannel){
                    submitBtn.click()
                }
                else if (isMultiCredit) {
                    // if they have already been notified about multi credit OR if they do not have an SMS channel selected go ahead and click the save button
                    if (multiCreditNotify) {
                        submitBtn.click()
                    }
                    setMultiCreditNotify(true);
                } else {
                    submitBtn.click()
                }
            }}
        />
        {/* conditional render this button for includesSMSChannel as something is preventing alternative click*/}
        <SaveButton className='automation-save-submit'
            sx={{ display: 'none' }}
        />
    </Toolbar>
}

const MessageAutomationCreate = () => {
    const [specialTextNotify, setSpecialTextNotify] = useState(false);
    const [estimatedCredits, setEstimatedCredits] = useState(1);
    const [estimatedCreditsRange, setEstimatedCreditsRange] = useState("");
    const [isMultiCredit, setIsMultiCredit] = useState(false); // TODO may be needed 
    const [multiCreditNotify, setMultiCreditNotify] = useState(false); // 
    const [includesSMSChannel, setIncludesSMSChannel] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();

    // save button for the form itself
    const submitBtn = document.querySelector('.automation-save-submit') as HTMLElement

    const onSuccess = (data: any) => {
        notify('Automation created', { type: 'info' });
        redirect('/messageautomations');
    }

    const onCancel = (data: any) => {
        redirect('/messageautomations');
    }

    useEffect(() => { // move template <create> option to top on automation pages
        document.body.classList.add("message-automations-page");
        return () => {
            document.body.classList.remove("message-automations-page");
        }
    })

    return (
        <Create
            className='automation-create'
            sx={{ maxWidth: '1020px' }}
            transform={transform}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='automation'
                toolbar={<CustomToolbar 
                            onCancel={onCancel}
                            isMultiCredit={isMultiCredit}
                            multiCreditNotify={multiCreditNotify}
                            setMultiCreditNotify={setMultiCreditNotify}
                            includesSMSChannel={includesSMSChannel}
                            submitBtn={submitBtn}/>}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Create Automation</h1>
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: '28px' }}>
                        <BooleanInput source='enabled' label='Enabled' defaultValue={false} />
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <p className='automation-label'>1. Name your automation:</p>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='automation-label'>2. Select your channels:</p>
                    <Grid item xs={12}>
                        <CustomChannelInput setIncludesSMSChannel={setIncludesSMSChannel} />
                    </Grid>
                </Grid>

                <ScheduleCriteria mode='create' />

                <SelectionCriteria mode='create' />

                <Grid {...gridProps} className='automation-richtext'>
                    <p className='automation-label'>5. Select your message:</p>
                    <Grid item xs={12}>
                        <FormDataConsumer>
                            {({ formData }) => {
                                if (formData.type === 'event' && formData.trigger_id) {
                                    return <GetTriggerAndFilter triggerId={formData.trigger_id} />
                                } else if (formData.type === 'appointment') {
                                    return <FilteredTemplates event_types={['General', 'Appointments']} />
                                } else {
                                    return <FilteredTemplates event_types={null} />
                                }
                            }}
                        </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => <>
                            {formData.template && (
                                <Grid item xs={12}>
                                    <RenderMessageTemplate template_id={formData.template}
                                        setEstimatedCredits={setEstimatedCredits}
                                        setSpecialTextNotify={setSpecialTextNotify}
                                        setEstimatedCreditsRange={setEstimatedCreditsRange}
                                        setIsMultiCredit={setIsMultiCredit}
                                        estimatedCredits={estimatedCredits}
                                        estimatedCreditsRange={estimatedCreditsRange}
                                        specialTextNotify={specialTextNotify}
                                        notGSMCompliantOnLoad={undefined}
                                        setNotGSMCompliantOnLoad={undefined} 
                                        includesSMSChannel={includesSMSChannel}
                                        />
                                </Grid>
                            )}
                        </>}
                    </FormDataConsumer>
                    {isMultiCredit && multiCreditNotify  && includesSMSChannel? <ConfirmAutomationMultiCredit estimatedCredits={estimatedCredits} estimatedCreditsRange={estimatedCreditsRange} setMultiCreditNotify={setMultiCreditNotify} /> : null}
                </Grid>
                <SendTestMessage origin='automation' />
            </SimpleForm>
        </Create>
    );
};

export default MessageAutomationCreate;
