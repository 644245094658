import { useEffect, useState } from 'react';
import { useUpdate, useGetIdentity, useRefresh, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Button, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, IconButton, TextField } from '@mui/material';
import { logActivity } from './logActivity';
import { fetchUtils } from 'react-admin';
import tokenManager from './tokenManager';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import dataProvider from './dataProvider';

const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
const httpClient = fetchUtils.fetchJson;

async function removeActionAPI(patient_id, action, date=0) {
    const accessToken = tokenManager.getToken('AuthToken');

    var args = {
        user_id: patient_id,
        action: action
    }
    if (date) {
        args.date = Number(date)
    }

    await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/user/remove_action`, {
        method: 'POST',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(args),
    }).catch((e)=> {
        console.log(`Error in resolveAction:removeAction()`)
        console.error(e)
        throw e
    })

}

export function useResolveAction(record) {

    const date = sessionStorage.getItem('testDate')

    var currRecord = record;
    const [update] = useUpdate();
    const refresh = useRefresh();
    const notify = useNotify();
    const navigate = useNavigate();
    const { identity } = useGetIdentity();

    const [callPatientMinutes, setCallPatientMinutes] = useState("")
    const [callPatientNotes, setCallPatientNotes] = useState("")
    const [inputError, setInputError] = useState(false)
    const [serverError, setServerError] = useState(false)

    const [CallPatientOpen, setCallPatientOpen] = useState(false);
    const [data, setData] = useState();

    useEffect(() => {
        
        var todayDate = Math.floor(Date.parse(new Date()) / 1000)
        dataProvider.get(`kaizenovate/provider/1.0.0/check-day-for-appointment?date=${todayDate}&userId=${record.id}`).then(result => {
            setData(result.data);
        });

    }, [record.id])

    var logs = record?.patient_actions
    if (data) {
        const index = logs.indexOf('Call Patient')
        if (index > -1) {
            logs.splice(index, 1)
        }
    }

    const handleCallPatientDialogOpen = () => {
        setCallPatientOpen(true)
    }

    const handleCallPatientDialogClose = () => {
        setCallPatientOpen(false)
        setInputError(false)
        setServerError(false)
    }

    const handleCallPatientDialogSubmit = async (event) => {
        event.target.disabled = true;
        setTimeout(() => event.target.disabled = false, 1000)

        // console.log("Minutes: " + callPatientMinutes)
        // console.log("Notes: " + callPatientNotes)
        // console.log("billingId: " + 'might not be able to get this')
        // console.log("patientId: " + record.id)
        // console.log("date: " + Date.parse(new Date()) / 1000)
        // console.log("log_name: " + identity?.name ?? "",)

        if (callPatientMinutes === "" || callPatientNotes === "") {
          setInputError(true)
          return
        } else if (inputError) {
          setInputError(false)
        }
        
        const accessToken = tokenManager.getToken();

        const result = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/post-call-patient`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                patientId: record.id,
                note: callPatientNotes,
                duration: callPatientMinutes,
                date: date ?? (Date.parse(new Date()) / 1000),
                log_name: identity?.name ?? ""
            }),
        }).catch((e)=> {
            setServerError(true)
            console.log(`Error in resolveAction:handleCallPatientDialogSubmit()`)
            console.error(e)
            event.target.disabled = false
            throw e
        })

        if (result.status !== 200) {
            setServerError(true)
            console.log("Error in post call patient")
        } else {
            setServerError(false)
            setInputError(false)

            await removeActionAPI(currRecord.id, 'Call Patient')

            setCallPatientOpen(false)
            refresh()
        }

        event.target.disabled = false;
    }

    const removeAction = async (actionToRemove, event) => {

        event.target.disabled = true

        if (actionToRemove.includes("Call Patient")) {
            handleCallPatientDialogOpen()
        } else if (actionToRemove === "HEP Pending Approval") {
            navigate(`/users/${record.id}/home-exercise-plan`)
        } else if (actionToRemove === "Ready for Billing") {
            navigate(`/billings?patient=${currRecord.id}&status=${encodeURIComponent('Ready for Billing')}`)
        } else {

            if (actionToRemove.includes("Approve HEP")) {
                notify("HEP management has moved. You can approve/deny HEPs in the patient's Home Exercise Plan tab", { type: 'info', autoHideDuration: 10000 });
            }

            await removeActionAPI(currRecord.id, actionToRemove)
            let logName = 'PROVIDER.PatientAction.Completed'

            await logActivity(logName, 'Resolved Patient Action', 'Completed: ' + actionToRemove, currRecord.id, 0, date)
            refresh()
        }
        event.target.disabled = false
    }

    return (
        <div>
            <div>
                <Dialog
                    open={CallPatientOpen}
                    //onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', pt: 2, pr: 2 }} >
                        <IconButton onClick={handleCallPatientDialogClose} >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', px: 2 }}>
                        {"Update Current 30-day Duration"}
                    </DialogTitle>
                    <DialogContent sx={{ px: 2 }} >
                        <DialogContentText id="alert-dialog-description">
                            {"How many minutes did you spend talking to the patient?"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>
                            Minutes
                        </DialogContentText>
                        <TextField id="callPatientMinutes" variant="outlined" type='number' sx={{ width: 1 }} onChange={(event) => {setCallPatientMinutes(event.target.value)}} />
                    </DialogContent>
                    <DialogContent>
                        <DialogContentText>
                            Phone Note
                        </DialogContentText>
                        <textarea id="callPatientNotes" onChange={(event) => {setCallPatientNotes(event.target.value)}} rows={6} style={{width:'98%', borderColor: '#ccc', borderRadius: '5px'}}></textarea>
                    </DialogContent>
                    <div className="input-error" style={{display: inputError ? 'block' : 'none', margin: 'auto'}}>
                      <p style={{fontSize: "0.75rem", color: "#d32f2f", marginBottom: 0}}>
                        Please enter a note and duration for the call.
                      </p>
                    </div>
                    <div className="server-error" style={{display: serverError ? 'block' : 'none', margin: 'auto'}}>
                      <p style={{fontSize: "0.75rem", color: "#d32f2f", marginBottom: 0}}>
                        We ran into an error trying to process your request. Please try again later.
                      </p>
                    </div>
                    <DialogActions sx={{ pr: 2, pb: 2 }} >
                        <Button sx={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }} variant='contained' onClick={(event) => handleCallPatientDialogSubmit(event)} autoFocus>
                            <SaveIcon />
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <FormGroup className="checkbox-form">
                {logs?.map(action => 
                    {
                        if(true) {
                            var modifiedLabel = action;
                            return (
                                <FormControlLabel key={action} control={<Checkbox onClick={(event) => removeAction(action, event)} />} label={modifiedLabel} checked={false} />
                            )
                        } else {
                            return (
                                <FormControlLabel key={action} control={<Checkbox onClick={() => removeAction(action)} />} label={action} />
                            )
                        }
                    }
                )}
            </FormGroup>
        </div>
    )
}