import * as React from 'react';
import { Inspector, LayoutClasses, LayoutProps, Loading, Sidebar, SkipNavigationButton, useSidebarState, Error, useRefresh, useLogout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { SelectChangeEvent, styled } from '@mui/material';
import { ErrorInfo, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { StreamChat } from 'stream-chat';
import dataProvider from '../dataProvider';
import tokenManager from '../tokenManager';

const Layout = (props: LayoutProps) => {
  
  // default react stuff
  const {
      appBarAlwaysOn,
      children,
      className,
      dashboard,
      error: errorComponent,
      title,
      ...rest
  } = props;

  const [open] = useSidebarState();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | undefined>(undefined);

  const handleError = (error: Error, info: ErrorInfo) => {
      setErrorInfo(info);
  };

  // custom stuff
  const [globalIntervalArray, setGlobalIntervalArray] = useState<any[]>([]);
  const [practices, setPractices] = useState([]);
  const [currPractice, setCurrPractice] = useState('xyz');
  const [client, setClient] = useState(StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY!, { timeout: 30000 }));
  const [chatLoading, setChatLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadCountError, setUnreadCountError] = useState(0);
  const refresh = useRefresh();
  const logout = useLogout();

  const listenEvents = ["channel.updated", "message.deleted", "message.new", "message.read", "notification.mark_read", "notification.mark_unread"]

  useEffect(() => {
    const listener = client.on((event) => {
      if (listenEvents.includes(event.type) && event.unread_channels !== undefined && event.unread_channels !== null && event.unread_channels !== (unreadCount + unreadCountError)) {
        setUnreadCount(event.unread_channels - unreadCountError)
      }
    })

    const connectionListener = client.on('connection.changed', (e) => {
      if (e.online) {
        console.log("Chat connection online")
      } else {
        console.log("Chat connection down")
      }
    })
    return () => {
      listener.unsubscribe()
      connectionListener.unsubscribe()
    }
  }, [client, unreadCount, unreadCountError])

  // beamer config
  // useEffect(() => {
  //   const configScript = document.createElement("script")
  //   configScript.type = "text/javascript"
  //   configScript.textContent = `
  //     var beamer_config = {
  //       product_id: "${process.env.REACT_APP_BEAMER_PRODUCT_ID}",
  //     };
  //   `
  //   document.body.appendChild(configScript)

  //   const embedScript = document.createElement("script")
  //   embedScript.type = "text/javascript"
  //   embedScript.src = "https://app.getbeamer.com/js/beamer-embed.js"
  //   embedScript.defer = true
  //   document.body.appendChild(embedScript)

  //   return () => {
  //     document.body.removeChild(configScript)
  //     document.body.removeChild(embedScript)
  //   }
  // }, [])

  useEffect(() => {
    client.getUnreadCount(sessionStorage.getItem('selectedPractice')!).then((result) => {
      let fakeUnreadCount = 0
      for ( const channel of result.channels) {
        if (channel.unread_count === 0) fakeUnreadCount++
      }
      setUnreadCountError(fakeUnreadCount)
      if (fakeUnreadCount > 0) {
        console.warn('fakeUnreadCount is greater than 0')
      }
    })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      const value = sessionStorage.getItem('selectedPractice')
      if (!value) {
        logout()
      }
    }, 60000)

    return () => clearInterval(interval);
  }, [logout])

  useEffect(() => {
      if(tokenManager.getToken()) {
          setChatLoading(true)
          dataProvider.get(`kaizenovate/provider/1.0.0/practices`).then(result => {
              setPractices(result.data);
              let selectedPracticeIndex = 0
              for (var index = result.data.length - 1; index >= 0; index--) {
                  if (result.data[index]['id'] === sessionStorage.getItem('selectedPractice')) {
                      selectedPracticeIndex = index
                      break
                  } else if (result.data[index]['status'] === 'active') {
                      selectedPracticeIndex = index
                  }
              }

              if (result.data[selectedPracticeIndex]['status'] === 'active') {
                  sessionStorage.setItem('selectedPractice',result.data[selectedPracticeIndex]['id'])
                  sessionStorage.setItem('selectedPracticeName', result.data[selectedPracticeIndex]['name'])
                  sessionStorage.setItem('selectedPracticeTZ',result.data[selectedPracticeIndex]['time_zone'])
                  sessionStorage.setItem('enhancedMessagingEnabled', result.data[selectedPracticeIndex]['enhanced_messaging_enabled'] ? 'true' : 'false')
                  sessionStorage.setItem('embodiConnectEnabled', result.data[selectedPracticeIndex]['embodi_connect_enabled'] ? 'true' : 'false')
                  sessionStorage.setItem('notificationEmailDomains', result.data[selectedPracticeIndex]['email_domains'])
                  sessionStorage.removeItem('keyOpenTasksDoctor')
                  sessionStorage.removeItem('keyOpenTasksActionType')
                  sessionStorage.removeItem('apptPROsDoctor')
                  setCurrPractice(result.data[selectedPracticeIndex]['id'])
                  //init chat
                  dataProvider.create(`messages/createToken`, { data: { userId: result.data[selectedPracticeIndex]['id'] } })
                      .then((createTokenResult) => {
                          client!.connectUser(
                              {
                                  id: result.data[selectedPracticeIndex]['id'],
                              },
                              createTokenResult.data.extra,
                          ).then((connectUserResult) => {
                              setUnreadCount((connectUserResult && connectUserResult.me && connectUserResult.me.unread_channels) ? connectUserResult.me.unread_channels : 0)
                              setChatLoading(false)
                          })
                      })
                      .catch((e) => {
                        console.log(e)
                        console.error('Error initializing chat')
                        setChatLoading(false)
                        refresh()
                      })
              }
          });
      }
      return () => { client!.disconnectUser(); }
  }, [client]);

  const handlePracticeChange = async (event: SelectChangeEvent) => {
      setChatLoading(true)
      window.location.href = '/#/'
      await client!.disconnectUser();
      sessionStorage.setItem('selectedPractice',event.target.value)
      for (const practice of practices) {
        if (practice['id'] === event.target.value) {
          sessionStorage.setItem('enhancedMessagingEnabled', practice['enhanced_messaging_enabled'] ? 'true' : 'false')
          sessionStorage.setItem('embodiConnectEnabled', practice['embodi_connect_enabled'] ? 'true' : 'false')
          sessionStorage.setItem('selectedPracticeTZ', practice['time_zone'])
          sessionStorage.setItem('selectedPracticeName', practice['name'])
          sessionStorage.setItem('notificationEmailDomains', practice['email_domains'])
          sessionStorage.removeItem('keyOpenTasksDoctor')
          sessionStorage.removeItem('keyOpenTasksActionType')
          sessionStorage.removeItem('apptPROsDoctor')
          break
        }
      }

      try {
        const initChat = async () => {
          const createTokenResult = await dataProvider.create(`messages/createToken`, { data: { userId: event.target.value } })
          const connectUserResult = await client!.connectUser(
            {
              id: event.target.value,
            },
            createTokenResult.data.extra,
          )
          setUnreadCount((connectUserResult && connectUserResult.me && connectUserResult.me.unread_channels) ? connectUserResult.me.unread_channels : 0)
          setChatLoading(false)
        }

        await initChat()
      } catch(e) {
        console.log(e)
        console.error('Error initializing chat')
        setChatLoading(false)
      }

      client.getUnreadCount(sessionStorage.getItem('selectedPractice')!).then((result) => {
        let fakeUnreadCount = 0
        for ( const channel of result.channels) {
          if (channel.unread_count === 0) fakeUnreadCount++
        }
        setUnreadCountError(fakeUnreadCount)
        if (fakeUnreadCount > 0) {
          console.warn('fakeUnreadCount is greater than 0')
        }
      })
      
      setCurrPractice(event.target.value as string);

      refresh()
  };

  // separate function to handle practice change with params
  const handlePracticeChangeParams = async (practiceString: string) => {
    setChatLoading(true)
    await client!.disconnectUser();
    sessionStorage.setItem('selectedPractice',practiceString)
    for (const practice of practices) {
      if (practice['id'] === practiceString) {
        sessionStorage.setItem('enhancedMessagingEnabled', practice['enhanced_messaging_enabled'] ? 'true' : 'false')
        sessionStorage.setItem('embodiConnectEnabled', practice['embodi_connect_enabled'] ? 'true' : 'false')
        sessionStorage.setItem('selectedPracticeTZ', practice['time_zone'])
        sessionStorage.setItem('selectedPracticeName', practice['name'])
        sessionStorage.setItem('notificationEmailDomains', practice['email_domains'])
        sessionStorage.removeItem('keyOpenTasksDoctor')
        sessionStorage.removeItem('keyOpenTasksActionType')
        sessionStorage.removeItem('apptPROsDoctor')
        break
      }
    }

    try {
      const initChat = async () => {
        const createTokenResult = await dataProvider.create(`messages/createToken`, { data: { userId: practiceString } })
        const connectUserResult = await client!.connectUser(
          {
            id: practiceString,
          },
          createTokenResult.data.extra,
        )
        setUnreadCount((connectUserResult && connectUserResult.me && connectUserResult.me.unread_channels) ? connectUserResult.me.unread_channels : 0)
        setChatLoading(false)
      }

      await initChat()
    } catch(e) {
      console.log(e)
      console.error('Error initializing chat')
      setChatLoading(false)
    }

    client.getUnreadCount(sessionStorage.getItem('selectedPractice')!).then((result) => {
      let fakeUnreadCount = 0
      for ( const channel of result.channels) {
        if (channel.unread_count === 0) fakeUnreadCount++
      }
      setUnreadCountError(fakeUnreadCount)
      if (fakeUnreadCount > 0) {
        console.warn('fakeUnreadCount is greater than 0')
      }
    })
    
    setCurrPractice(practiceString as string);

    refresh()
  };

  if (chatLoading) {
    return <Loading />
  }

  return (
      <Core className={'layout ' + className} {...rest}>
          <SkipNavigationButton />
          <div className={LayoutClasses.appFrame}>
              <AppBar open={open} title={title} alwaysOn={appBarAlwaysOn} practices={practices} currpractice={currPractice} handlepracticechange={handlePracticeChange} handlePracticeChangeParams={handlePracticeChangeParams} globalIntervalArray={globalIntervalArray} setGlobalIntervalArray={setGlobalIntervalArray}/>
              <main className={LayoutClasses.contentWithSidebar}>
                  <Sidebar appBarAlwaysOn={appBarAlwaysOn}>
                      <Menu hasDashboard={!!dashboard} unreadCount={unreadCount} />
                  </Sidebar>
                  <div id="main-content" className={LayoutClasses.content}>
                      <ErrorBoundary
                          onError={handleError}
                          fallbackRender={({ error, resetErrorBoundary }) => (
                              <Error
                                  error={error}
                                  errorComponent={errorComponent}
                                  errorInfo={errorInfo}
                                  resetErrorBoundary={resetErrorBoundary}
                                  title={title}
                              />
                          )}
                      >
                          <Suspense fallback={<Loading />}>
                              {children}
                          </Suspense>
                      </ErrorBoundary>
                  </div>
              </main>
              <Inspector />
          </div>
      </Core>
  );
};

export default Layout;



const Core = styled('div', {
  name: 'RaLayout',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  minWidth: 'fit-content',
  width: '100%',
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(7),
      },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
      display: 'flex',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
      }),
  },
  [`& .${LayoutClasses.content}`]: {
      backgroundColor: theme.palette.background.default,
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: 0,
      [theme.breakpoints.up('xs')]: {
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(1),
      },
  },
}));
