import { 
    Avatar, 
    ChannelPreviewUIComponentProps, 
    useChatContext 
} from 'stream-chat-react';
import './CustomPreview.scss';
import { Badge } from '@mui/material';
import { useEffect, useState } from 'react';

export const CustomPreview = (
    // { client }, 
    props: ChannelPreviewUIComponentProps) => {

    const { channel, setActiveChannel } = props;
    const { channel: activeChannel } = useChatContext();
    const [unreadCount, setUnreadCount] = useState(channel.state.unreadCount || 0);
    const selected = channel?.id === activeChannel?.id;

    const listenEvents = ["notification.mark_read", "notification.mark_unread"]

    const renderMessageText = () => {
        const lastMessageText = channel.state.messages[channel.state.messages.length - 1]?.text;

        const text = lastMessageText || 'message text';

        return text.length < 60 ? lastMessageText : `${text.slice(0, 70)}...`;
    };

    let listener = channel.on((event) => {
      if (listenEvents.includes(event.type) && event.unread_count !== undefined && event.unread_count !== null && event.unread_count !== unreadCount) {
        setUnreadCount(Number(event.unread_messages ?? 0));
      }
    })

    useEffect(() => {
      setUnreadCount(channel.state.unreadCount);
    }, [channel.state.unreadCount])

    useEffect(() => {
      return () => {
        listener.unsubscribe();
      }
    }, [])

    // TODO change this so it checks for email or id instead of name
    const currentChannelPatient = Object.values(channel.state.members).find((user) => user.user!.id !== sessionStorage.getItem('selectedPractice'));

    // console.log(currentChannelPatient)

    // if (!channel.state.messages.length) return null;

    return ( 
        <div>
            <div
                className={selected ? 'channel-preview__container selected' : 'channel-preview__container'}
                onClick={() =>  setActiveChannel!(channel)}
            >
                <Badge
                    badgeContent={unreadCount}
                    color='error'
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Avatar image={currentChannelPatient?.user?.image} size={64} />
                </Badge>
                <div className='channel-preview__content-wrapper'>
                    <div className='channel-preview__content-top'>
                        <p className='channel-preview__content-name'>{currentChannelPatient?.user?.name || 'Unnamed Channel'}</p>
                        {/* <p className='channel-preview__content-name'>{channel.data?.subtitle}</p> */}
                    </div>
                    <p className='channel-preview__content-message'>{renderMessageText()}</p>
                </div>
            </div>
            <div className='message-divider'></div>
        </div>
    );
};
