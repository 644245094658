import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as MUIButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const ConfirmSMSCredits = ({ setScheduleOpen, sendNowPressed, setSendNowPressed, setCreditNotify, credits, estimatedAudience, estimatedCredits, estimatedCreditsRange,  }) => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    useEffect(() => {
        if (!hasOpened) {
            setOpen(true);
            setHasOpened(true);
        }
        
    }, [hasOpened]);

    let minCredits;
    let maxCredits;

    if (estimatedCreditsRange.includes("-")) {
        let estimatedCreditsArray = estimatedCreditsRange.split("-")
        minCredits = parseInt(estimatedCreditsArray[0])
        maxCredits = parseInt(estimatedCreditsArray[1])
    }

    let minBroadCastUsage = (parseInt(minCredits) * parseInt(estimatedAudience));
    let maxBroadCastUsage = (parseInt(maxCredits) * parseInt(estimatedAudience));

    let estimatedUsage = minCredits && maxCredits > 1 ?  `${minBroadCastUsage}-${maxBroadCastUsage}` : (estimatedAudience * estimatedCredits);
    let overageRate = .02 // May need to get this value dynamically 

    let estimatedUsageAfterMessage = (credits.usedCredits + estimatedUsage) // this is adding a string to a number currently need to fix for separate minCredits maxCredits average credits stuff
    let estUsageAfterRange = `${credits.usedCredits + minBroadCastUsage}-${credits.usedCredits + maxBroadCastUsage}`;
    
    let warningStyle = { color: estimatedCredits > 1 || estimatedCreditsRange.length > 1 || parseInt(estimatedCreditsRange) > 1 || estimatedCredits !== 1 ? "red" : "inherit" }

    let estimatedCreditsOverage = (credits.usedCredits + estimatedUsage) - credits.planCredits ;
    let estimatedCreditsOverageDollar = (estimatedCreditsOverage * overageRate).toLocaleString("en-US", { style: "currency", currency: "USD" });

    let overageRangeMin = (parseInt(credits.usedCredits) + minBroadCastUsage) - parseInt(credits.planCredits);
    let overageRangeMax = (parseInt(credits.usedCredits) + maxBroadCastUsage) - parseInt(credits.planCredits);

    let overageRangeMinDollar = ((overageRangeMin) * overageRate).toLocaleString("en-US", { style: "currency", currency: "USD" });
    let oveageRangeMaxDollar = ((overageRangeMax) * overageRate).toLocaleString("en-US", { style: "currency", currency: "USD" });

    return (
        <Dialog
        sx={{
            "& .MuiDialog-paper": {
              maxWidth: "480px", 
              width: "100%",
              margin: "25px",
            },
          }}
            open={open}
            onClose={() => {
                setCreditNotify(false);
                setOpen(false);
                setSendNowPressed(false);
                setScheduleOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Authorize SMS Credits"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your plan includes {credits.planCredits} SMS credits per month.
                    </DialogContentText>
                    <p>Monthly Credits used so far: {credits.usedCredits} / {credits.planCredits}</p>
                    <div>This Broadcast Credit Usage</div>
                    <div style={{ color: estimatedAudience > 100 || estimatedAudience === 0 ? "red" : "inherit" }}>
                        Estimated number of messages: {estimatedAudience}
                    </div>
                    <div>Estimated credits per message: <span style={warningStyle}> {estimatedCreditsRange ? estimatedCreditsRange : estimatedCredits}</span></div>
                    <div>Estimated total credits for broadcast: <span style={warningStyle}> {estimatedUsage}</span> </div>
                    {isNaN(overageRangeMin) ? <p>
                        Estimated total credits after message: <span style={{ color: estimatedUsageAfterMessage > credits.planCredits ? "red" : "inherit" }}>{estimatedUsageAfterMessage} / {credits.planCredits}</span>
                    </p> : null}
                    {!isNaN(overageRangeMin) ? <p>
                        Estimated total credits after message: <span style={{ color: (credits.usedCredits + maxBroadCastUsage) > credits.planCredits ? "red" : "inherit" }}>{estUsageAfterRange} / {credits.planCredits}</span>
                    </p> : null}
                    {estimatedUsageAfterMessage > credits.planCredits ? <p style={{ color: "red" }}>We estimate sending this broadcast will cause you to exceed your monthly SMS credit allocation by <strong>{ estimatedCreditsOverage} SMS Credits which will incure a cost of {estimatedCreditsOverageDollar} </strong> </p> : null}
                    {(credits.usedCredits + maxBroadCastUsage) > credits.planCredits ? <p style={{ color: "red" }}>We estimate sending this broadcast will cause you to exceed your monthly SMS credit allocation by <strong>{overageRangeMin > 0 ? `${overageRangeMin}-${overageRangeMax}`: `by a possible ${overageRangeMax}` } SMS Credits which will incure a cost of {overageRangeMin > 0 ?`${overageRangeMinDollar}-${oveageRangeMaxDollar}` :`${oveageRangeMaxDollar}` }</strong> </p> : null}
                    
                <DialogContentText id="alert-dialog-description">

                    <strong>
                        Note: If you exceed {credits.planCredits} SMS credits in a given month, you will be charged {overageRate} per additional SMS credit. Both automations and broadcasts are included in your monthly SMS credit allocation.
                    </strong>
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between', margin: '5px 14px 15px 14px' }}>
                <MUIButton onClick={() => {
                    setCreditNotify(false);
                    setOpen(false);
                    setSendNowPressed(false);
                    setScheduleOpen(false);
                }} variant="contained" sx={{
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    textTransform: "none", 
                    height: "50px", 
                    width: "150px", 
                    backgroundColor: '#707075',
                    color: 'white',
                }}>
                    <Typography >Cancel</Typography>
                </MUIButton>
                <MUIButton variant="contained" onClick={() => {
                    if (sendNowPressed) {
                        // send message now
                        const submitBtn = document.querySelector('.broadcase-send-submit') as HTMLElement | null;
                        if (submitBtn) {
                            submitBtn.click();
                        }
                    }
                    setCreditNotify(false);
                    setSendNowPressed(false);
                    setOpen(false);
                }} color="primary" sx={{
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    textTransform: "none", 
                    height: "50px", 
                    width: "150px", 
                }}>
                    <Typography>Confirm</Typography>
                </MUIButton>
            </DialogActions>
        </Dialog>
    );
}