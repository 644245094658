import { dynamicTextHandling } from "./dynamicTextHandling";
import { isGSM7Compliant } from "./gsm7ComplianceHelpers";
import { htmlDecode } from "./htmlDecode";

// help function to either return dynamic text options and processed conditions or set various state as required
export const creditProcessing = (  formData, specialTextNotify, setSpecialTextNotify, estimatedCredits, setEstimatedCredits, estimatedCreditsRange, setEstimatedCreditsRange, templateContent )=>{

    // handles the case where the user selected a template and then unchecked the use_template checkbox, also if they unselect the template
    if ((!formData.use_template && templateContent) || !formData.template) {
        templateContent = undefined
    }
    let text = templateContent? templateContent : formData.custom_message

    let gsm7compliant = true;
    if (text) {
        gsm7compliant = isGSM7Compliant(text.replace(/^<p>|<\/p>$/gm, '')) // need to remove html tags 
    }


    let parsedMessage = text?.replace(/<p>/gm, '') // removes html tags
    parsedMessage = parsedMessage?.replace(/<\/p>/gm, '\n') // removes html tags

    // handle dynamic text
    let {dynamicTextKeys, estimationLengthMin, estimationLengthMax} = dynamicTextHandling(parsedMessage)

    let hasTrailingNewLine = parsedMessage?.endsWith('\n'); // if we have an instance of a trailing new line - we shouldn't so we offset the count by 1

    const messageLength = text?.length > 0 ? ((Array.from(htmlDecode(parsedMessage)!).length - (hasTrailingNewLine ? 1 : 0))) : 0;

    const newEstimatedCredits = Math.ceil(messageLength / (gsm7compliant ? 160 : 70));
    if (newEstimatedCredits !== estimatedCredits) {
        setEstimatedCredits(newEstimatedCredits);
    }
    // check and handle dynamic text
    let hasDynamicText = false
    hasDynamicText = dynamicTextKeys.some(option => text?.includes(option));
    if (!gsm7compliant && !specialTextNotify) {
        setSpecialTextNotify(true);
    } else if (gsm7compliant && specialTextNotify) {
        setSpecialTextNotify(false);
    }

    const dynamicTextRegex = new RegExp(dynamicTextKeys.join('|'), 'g');
    const displayMin = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - (hasTrailingNewLine ? 1 : 0)) + estimationLengthMin) : 0;
    const displayMax = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - (hasTrailingNewLine ? 1 : 0)) + estimationLengthMax) : 0;
    // const displayAvg = parsedMessage?.length ? ((Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthAvg) : 0;

    if (hasDynamicText) {
        let minCalc = Math.ceil(displayMin / (gsm7compliant ? 160 : 70))
        let maxCalc = Math.ceil(displayMax / (gsm7compliant ? 160 : 70))

        if (minCalc !== maxCalc) {
            setEstimatedCreditsRange(`${minCalc}-${maxCalc}`);
        } else {
            setEstimatedCreditsRange(`${maxCalc}`);
        }
    }

    if (!hasDynamicText) {
        setEstimatedCreditsRange("");
    }
        return { parsedMessage, hasDynamicText, displayMin, displayMax, htmlDecode, hasTrailingNewLine, gsm7compliant, messageLength }

}