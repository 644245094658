import { useEffect, useState } from "react";
import { useGetOne } from "react-admin";

export function MessagesRecordWrapper({ currentRecord, setRecord, currentChannelPatient }) {
  const record = useGetOne('users', { id: currentChannelPatient?.user_id }, {staleTime: Infinity, refetchOnWindowFocus: false}).data;

  useEffect(() => {
    if (currentRecord?.id !== record?.id || currentRecord?.patient_actions.length !== record?.patient_actions.length) {
      setRecord(record);
    }
  }, [record?.id, record?.patient_actions, setRecord, currentRecord])

  return null
}