import { useEffect } from "react";
import { TextInput } from "react-admin";
import { useFormContext } from "react-hook-form";

export const EstimatedCredits = ({ estimatedCredits }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        if (estimatedCredits) {
            // console.log(estimatedCredits);
            setValue('estimated_credits', estimatedCredits);
        }
    }, [estimatedCredits, setValue]);

    return estimatedCredits ? <TextInput source="estimated_credits" sx={{ display: 'none' }} /> : null;
}